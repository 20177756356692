import React, { forwardRef, useCallback, useEffect, useState } from 'react'
import { PageLink } from '../../../_metronic/layout/core'
import {
  Box,
  Button,
  Card, 
  CardContent,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Slider,
  SpeedDial,
  SpeedDialAction,
  Tooltip,
  Typography,
  Avatar,
  darken,
  lighten,
  styled,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material'
import { DataGridPro, GridToolbarContainer, GridToolbarColumnsButton, GridToolbarFilterButton, GridToolbarDensitySelector } from '@mui/x-data-grid-pro'
import { LoadingButton } from '@mui/lab'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { enqueueSnackbar } from 'notistack'
import { Transitions, Slide} from '@mui/material'

// Icons
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import FileDownloadIcon from '@mui/icons-material/FileDownload'
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined'
import CheckIcon from '@mui/icons-material/Check'
import BlockIcon from '@mui/icons-material/Block'
import SettingsIcon from '@mui/icons-material/Settings'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import CloseIcon from '@mui/icons-material/Close'
import LockIcon from '@mui/icons-material/Lock'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'

// Components
import JourneyTimig from './JourneyTiming'
import LeaderBoard from './JourneyLeaderBoard'
import Chat from './JourneyChat'
import JourneyTimePreview from './JourneyTimePreview'
import JourneyAwardPreview from './JourneyAwardPreview'
import Loading from '../../../_metronic/layout/components/Loading'
import { useWebSocket } from '../ChatService/useChat'
import { TransitionProps } from '@mui/material/transitions' 

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />
})

// StyledDataGrid
const getBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.9) : lighten(color, 0.9)

const getHoverBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.8) : lighten(color, 0.8)

// StyledDataGrid güncellemesi
const StyledDataGrid = styled(DataGridPro)(({ theme }) => ({
  backgroundColor: 'white',
  '& .super-app-theme--Open': {
    backgroundColor: getBackgroundColor(theme.palette.info.main, theme.palette.mode),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(theme.palette.info.main, theme.palette.mode),
    },
  },
  '& .MuiDataGrid-footerContainer': {
    padding: '0px',
    height: 30
  },
  '& .MuiDataGrid-cell:hover': {
    cursor: 'pointer',
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    fontWeight: 'bold',
    color: '#A1A5B7',
    fontSize: 12,
  },
  '& .MuiDataGrid-cell:focus': {
    outline: 'none',
  },
  '& .MuiDataGrid-columnHeaders': {
    backgroundColor: '#E5F1FD',
  },
}));

const Journeys: React.FC = () => {
  const LoginState = useSelector((state: any) => state.Login)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  
  // States
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [Journey, setJourney] = useState<any>([])
  const [selectQuestion, setSelectQuestion] = useState<any[]>([])
  const [speedDialOpen, setSpeedDialOpen] = useState(false)
  const [journeyStatus, setJourneyStatus] = useState(-1)
  const [loadingStatus, setLoadingStatus] = useState(false)
  const [openWarnModal, setOpenWarnModal] = useState(false)
  const [loadingExport, setLoadingExport] = useState(false)
  
  // Modal states
  const [showTimePreview, setShowTimePreview] = useState(false)
  const [showLeader, setShowLeader] = useState(false)
  const [showAward, setShowAward] = useState(false)
  const [showChat, setShowChat] = useState(false)
  const [journeyTime, setJourneyTime] = useState<any>({ startDate: null, endDate: null })
  const [journeyName, setJourneyName] = useState('')
  const [awardDetail, setAwardDetail] = useState<any>({})
  
  // Chat states
  const socket: WebSocket | null = useWebSocket() as WebSocket | null
  const [messages, setMessages] = useState<string[]>([])
  const [message, setMessage] = useState('')
  const [journeyChatIsOn, setJourneyChatIsOn] = useState(false)
  const [journeyUnReadedMessage, setJourneyUnReadedMessage] = useState(false)
  const [journeyChatDisable, setJourneyChatDisable] = useState(false)

  // Fetch journeys
  useEffect(() => {
    const getJourneyList = async () => {
      setIsLoading(true)
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/Game/getJourneyList`, {
          headers: {
            Authorization: `Bearer ${LoginState.loginData.access_token}`,
          },
        })
        setJourney(response.data)
        setIsLoading(false)
      } catch (err) {
        setIsLoading(false)
      }
    }
    getJourneyList()
  }, [LoginState.loginData.access_token])

  const handleStatusChange = async () => {
    if (selectQuestion.length === 0) return;

    const selectedJourneyId = selectQuestion[0];
    setOpenWarnModal(false);

    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/Game/changeJourneyStatus`,
        {
          journeyId: selectedJourneyId,
          statusId: journeyStatus,
        },
        {
          headers: {
            Authorization: `Bearer ${LoginState.loginData.access_token}`,
          },
        }
      );

      // Başarı mesajı
      enqueueSnackbar('Journey status updated successfully', { variant: 'success' });

      // Listeyi yeniden yükle
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/Game/getJourneyList`, {
        headers: {
          Authorization: `Bearer ${LoginState.loginData.access_token}`,
        },
      });
      setJourney(response.data);
    } catch (error) {
      enqueueSnackbar('Failed to update journey status', { variant: 'error' });
    } finally {
      setJourneyStatus(-1);
    }
  };

  const handleOpenWarnModal = (statusId) => {
    if (selectQuestion.length === 0) return;

    setJourneyStatus(statusId);
    setOpenWarnModal(true);
  };

  // DataGrid columns
  const columns: any = [
    {
      field: 'journeyId',
      headerName: 'JOURNEY ID',
      width: 120,
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'journeyName',
      headerName: 'JOURNEY NAME',
      width: 200,
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'assignmentType',
      headerName: 'TYPE',
      width: 120,
      headerAlign: 'left',
      align: 'left',
      valueFormatter: (params: any) => params.value === 0 ? 'Public' : 'Private',
      type: 'singleSelect',
      valueOptions: [
        { value: 0, label: 'Public' },
        { value: 1, label: 'Private' }
      ]
    },
    {
      field: 'successRate',
      headerName: 'SUCCESS RATE',
      width: 130,
      type: 'number',
      headerAlign: 'left',
      align: 'left',
      valueFormatter: (params: any) => `${params.value.toFixed(2)}%`,
    },
    {
      field: 'progress',
      headerName: 'PROGRESS',
      width: 130,
      type: 'number',
      headerAlign: 'left',
      align: 'left',
      valueFormatter: (params: any) => `${params.value.toFixed(2)}%`,
    },
    {
      field: 'journeyAwardWinners',
      headerName: 'AWARD',
      width: 130,
      headerAlign: 'left',
      align: 'left',
      valueFormatter: (params: any) => {
        switch (params.value) {
          case 0: return 'None';
          case 1: return 'Champion';
          case 2: return '3 Winners';
          case 3: return 'Top 10';
          case 4: return 'Top 25';
          case 5: return 'Top 100';
          case 6: return 'All Finishers';
          default: return 'None';
        }
      },
    },
    {
      field: 'chatIsOn',
      headerName: 'CHAT',
      width: 100,
      headerAlign: 'left',
      align: 'left',
    },
    {
      field: 'statusId',
      headerName: 'STATUS',
      width: 130,
      headerAlign: 'center',
      align: 'center',
      type: 'singleSelect',
      valueOptions: [
        { value: 0, label: 'Draft' },
        { value: 1, label: 'Ready' },
        { value: 2, label: 'Started' },
        { value: 3, label: 'Finished' },
        { value: 5, label: 'Archived' }
      ],
      renderCell: (params: any) => (
        <Chip
          label={params.row.journeyStatus}
          color={
            params.value === 0 ? 'default' :
            params.value === 1 ? 'primary' :
            params.value === 2 ? 'success' :
            params.value === 3 ? 'secondary' :
            params.value === 5 ? 'default' : 'default'
          }
          variant="outlined"
          size="small"
        />
      )
    },
    {
      field: 'activityList',
      headerName: 'ACTIVITIES',
      width: 130,
      type: 'number',
      headerAlign: 'left',
      align: 'left',
      valueGetter: (params: any) => params.value.length,
    },
    {
      field: 'startDate',
      headerName: 'START DATE',
      width: 180,
      type: 'dateTime',
      headerAlign: 'left',
      align: 'left',
      valueFormatter: (params: any) => {
        if (!params.value) return ''; // Tarih null ise boş döner
        const date = new Date(params.value); // Tarihi dönüştür
        return date.toLocaleString(); // Lokal biçimde stringe çevir
      },
      valueGetter: (params: any) => {
        if (!params.value) return null; // Tarih yoksa null döner
        return new Date(params.value); // Tarihi dönüştür ve döner
      },
    },
    {
      field: 'endDate',
      headerName: 'END DATE',
      width: 180,
      type: 'dateTime',
      headerAlign: 'left',
      align: 'left',
      valueFormatter: (params: any) => {
        if (!params.value) return ''; // Tarih null ise boş döner
        const date = new Date(params.value); // Tarihi dönüştür
        return date.toLocaleString(); // Lokal biçimde stringe çevir
      },
      valueGetter: (params: any) => {
        if (!params.value) return null; // Tarih yoksa null döner
        return new Date(params.value); // Tarihi dönüştür ve döner
      },
    },
    {
      field: 'createdDate',
      headerName: 'CREATED DATE',
      width: 180,
      type: 'dateTime',
      headerAlign: 'left',
      align: 'left',
      valueFormatter: (params: any) => {
        if (!params.value) return ''; // Tarih null ise boş döner
        const date = new Date(params.value); // Tarihi dönüştür
        return date.toLocaleString(); // Lokal biçimde stringe çevir
      },
      valueGetter: (params: any) => {
        if (!params.value) return null; // Tarih yoksa null döner
        return new Date(params.value); // Tarihi dönüştür ve döner
      },
    },
  ]

  // Custom Toolbar
  const CustomToolbar = () => (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <LoadingButton
        size="small"
        onClick={downloadExcel}
        endIcon={<FileDownloadIcon />}
        loading={loadingExport}
        loadingPosition="end"
        variant="outlined"
      >
        Export
      </LoadingButton>
    </GridToolbarContainer>
  )

  // Journey Actions
  const handleEdit = () => {
    if (selectQuestion.length > 0) {
      navigate(`/journey/learning-journey/create/?id=${selectQuestion[0]}`)
    }
  }

  const handleDuplicate = async () => {
    if (selectQuestion.length > 0) {
      try {
        await axios.post(
          `${process.env.REACT_APP_API_URL}/Game/duplicateJourney/${selectQuestion[0]}`,
          '',
          {
            headers: {
              Authorization: `Bearer ${LoginState.loginData.access_token}`,
            },
          }
        )
        // Refresh journey list
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/Game/getJourneyList`,
          {
            headers: {
              Authorization: `Bearer ${LoginState.loginData.access_token}`,
            },
          }
        )
        setJourney(response.data)
        enqueueSnackbar('Journey duplicated successfully', { variant: 'success' })
        // Sayfayı yeniden yükle
        window.location.reload();
      } catch (error) {
        enqueueSnackbar('Failed to duplicate journey', { variant: 'error' })
      }
    }
  }

  const handleCreate = () => {
    navigate('/journey/learning-journey/create')
  }

  // Download Excel
  const downloadExcel = async () => {
    setLoadingExport(true)
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_URL}/report/journeyReport?isExcel=1`,
        {},
        {
          responseType: 'blob',
          headers: {
            Authorization: `Bearer ${LoginState.loginData.access_token}`,
          },
        }
      )
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.download = 'journeys.xlsx'
      link.click()
      window.URL.revokeObjectURL(url)
      setLoadingExport(false)
    } catch (error) {
      setLoadingExport(false)
      enqueueSnackbar('Export failed', { variant: 'error' })
    }
  }

  // Component dışında veya içinde utility fonksiyonu olarak tanımlayabiliriz
  const formatDate = (dateString: string | null): string => {
    if (!dateString) return '';
    
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric'
    };
    return date.toLocaleDateString('tr-TR', options);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (speedDialOpen) {
        const target = event.target as HTMLElement;
        if (!target.closest('.settings-dropdown')) {
          setSpeedDialOpen(false);
        }
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [speedDialOpen]);

  const handleCloseSpeedDial = () => {
    setSpeedDialOpen(false)
  }
  const handleOpenSpeedDial = () => {
    setSpeedDialOpen(true)
  }        

  if (isLoading) return <Loading />

  return (
    <>
      {/* Header */}
      <div style={{
        display: 'flex',
        height: 40,
        width: '80%',
        marginTop: '-50px',
        cursor: 'default',
        pointerEvents: 'none',
        userSelect: 'none',
      }}>
        <Typography fontSize={14} fontWeight={'bold'} color={'white'} marginLeft={4}>
          Journeys
        </Typography>
      </div>

      {/* Main Container */}
      <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column',
        height: 'calc(100vh)',
        p: 2,
        mt: -8,
      }}>
        {/* Action Buttons Container */}
        <Box sx={{ 
          display: 'flex', 
          justifyContent: 'flex-end', 
          gap: 1, 
          mb: 2,
          mt: -1.9,
          mr: 6.8,
          px: 2
        }}>
          <Tooltip title="Add New">
            <IconButton 
              onClick={handleCreate}
              sx={{ 
                backgroundColor: 'white',
                '&:hover': { backgroundColor: '#f5f5f5' },
                height: 40,
                width: 40
              }}
            >
              <AddIcon sx={{ fontSize: '30px'}} />
            </IconButton>
          </Tooltip>
          
          {selectQuestion.length > 0 && (
            <>
              <Tooltip title="Edit">
                <IconButton 
                  onClick={handleEdit}
                  sx={{ 
                    backgroundColor: 'white',
                    '&:hover': { backgroundColor: '#f5f5f5' },
                    height: 40,
                    width: 40
                  }}
                >
                  <EditIcon sx={{ fontSize: '30px'}} />
                </IconButton>
              </Tooltip>
              
              <Tooltip title="Duplicate">
                <IconButton 
                  onClick={handleDuplicate}
                  sx={{ 
                    backgroundColor: 'white',
                    '&:hover': { backgroundColor: '#f5f5f5' },
                    height: 40,
                    width: 40
                  }}
                >
                  <ContentCopyOutlinedIcon sx={{ fontSize: '30px'}} />
                </IconButton>
              </Tooltip>

              <Box
                display='flex'
                justifyContent='flex-end'
                alignItems='center'
                alignContent='center'
                sx={{ 
                  height: 50,
                  zIndex: 1000,
                }}
              >
                <Tooltip arrow title='Change Status' placement='top'>
                  <SpeedDial
                    ariaLabel='SpeedDial openIcon example'
                    style={{ transform: 'scale(0.73)', marginTop: '125px' }}
                    icon={
                      speedDialOpen ? (
                        <ExpandMoreIcon fontSize='large' />
                      ) : (
                        <SettingsIcon fontSize='large' />
                      )
                    }
                    openIcon={<DeleteIcon fontSize='large' />}
                    onOpen={handleOpenSpeedDial}
                    FabProps={{ color: 'inherit' }}
                    direction='down'
                  >
                    <SpeedDialAction
                      style={{ transform: 'scale(0.70)' }}
                      delay={1000}
                      key='Ready'
                      icon={<CheckIcon />}
                      tooltipTitle='Ready'
                      onClick={(event) => {
                        event.stopPropagation(); // Tıklamayı SpeedDial'a yayılmasını engelle
                        handleOpenWarnModal(1); // İlgili işlemi başlat
                        setSpeedDialOpen(false); // Manuel olarak kapatma, işlemin sonunda yapabilirsiniz
                      }}
                    />

                    <SpeedDialAction
                      style={{ transform: 'scale(0.70)' }}
                      delay={1000}
                      key='Pause'
                      icon={<BlockIcon />}
                      tooltipTitle='Pause'
                      onClick={(event) => {
                        event.stopPropagation(); // Tıklamayı SpeedDial'a yayılmasını engelle
                        handleOpenWarnModal(0); // İlgili işlemi başlat
                        setSpeedDialOpen(false); // Manuel olarak kapatma, işlemin sonunda yapabilirsiniz
                      }}
                    />  

                    <SpeedDialAction
                      style={{ transform: 'scale(0.70)' }}
                      delay={1000}
                      key='Delete'
                      icon={<DeleteIcon />}
                      tooltipTitle='Delete'
                      onClick={(event) => {
                        event.stopPropagation(); // Tıklamayı SpeedDial'a yayılmasını engelle
                        handleOpenWarnModal(4); // İlgili işlemi başlat
                        setSpeedDialOpen(false); // Manuel olarak kapatma, işlemin sonunda yapabilirsiniz
                      }}
                    />
                  </SpeedDial>
                </Tooltip>
              </Box>
            </>
          )}
        </Box>

        {/* Content Container */}
        <Box sx={{ 
          display: 'flex', 
          gap: 2, 
          flex: 1,
          minHeight: 0, // Önemli: Taşmayı önler
        }}>
          {/* DataGrid Container */}
          <Box sx={{ 
            flex: selectQuestion.length > 0 ? '0 0 70%' : '0 0 100%',
            backgroundColor: 'white',
            borderRadius: '12px',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column'
          }}>
            <StyledDataGrid
              rows={Journey}
              columns={columns}
              getRowId={(row) => row.journeyId}
              checkboxSelection
              onRowSelectionModelChange={(newSelection) => {
                if (newSelection.length > 1) {
                  const lastSelected = newSelection[newSelection.length - 1]
                  setSelectQuestion([lastSelected])
                } else {
                  setSelectQuestion(newSelection)
                }
              }}
              rowSelectionModel={selectQuestion}
              slots={{
                toolbar: CustomToolbar,
              }}
              sx={{
                flex: 1,
                boxShadow: 'none',
                border: 'none',
                '& .MuiDataGrid-footerContainer': {
                  padding: '0px',
                  height: 30,
                },
                '& .MuiDataGrid-cell:hover': {
                  cursor: 'pointer',
                },
                '& .MuiDataGrid-columnHeaderTitle': {
                  fontWeight: 'bold',
                  color: '#A1A5B7',
                  fontSize: 12,
                },
                '& .MuiDataGrid-cell:focus': {
                  outline: 'none',
                },
                '& .MuiDataGrid-columnHeaders': {
                  backgroundColor: '#E5F1FD',
                },
              }}
            />
          </Box>

           {/* Detail Container */}
           {selectQuestion.length > 0 && (
            <Box sx={{ 
              flex: '0 0 30%',
              display: 'flex',
              overflow: 'hidden' // Taşmayı önler
            }}>
              {Journey.filter((J: any) => J.journeyId === selectQuestion[0]).map((J: any) => (
                <Card
                  key={J.journeyId}
                  sx={{
                    width: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    color: 'white',
                    backgroundColor: '#1e1e2d',
                    border: J.statusId === 0 
                      ? '5px solid #b0b0b0'
                      : J.statusId === 4 
                      ? '5px solid #ef5350'
                      : '5px solid #50cd89',
                    borderRadius: '15px',
                    overflow: 'hidden' // Taşmayı önler
                  }}
                >
                  {/* Header */}
                  <div className='text-center text-uppercase' style={{
                    width: '100%',
                    height: '30px',
                    background: 'black',
                    borderRadius: '15px 15px 0px 0px',
                    borderBottom: "2px solid #707070",
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}>
                    <span style={{ fontSize: '20px' }}>{J.journeyName}</span>
                  </div>

                  {/* Content */}
                  <CardContent sx={{ 
                    flexGrow: 1,
                    p: 2,
                    display: 'flex',
                    overflow: 'hidden'
                  }}>
                    <div className='row' style={{ width: '100%', height: '100%', position: 'relative' }}>
                      {/* Left Progress Slider */}
                      <div className='col-1' style={{ 
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        position: 'absolute',
                        left: 0,
                        zIndex: 1
                      }}>
                        <Avatar 
                          variant='rounded' 
                          src='/media/journey/goal_edited.png' 
                          sx={{ 
                            height: "auto", 
                            width: "25px", 
                            objectFit: "contain"
                          }} 
                        />
                        <Slider
                          sx={{
                            '&.Mui-disabled': {
                              color: '#50CD89',
                              height: '90%',
                              marginTop: '10px',
                              width: '25%',
                              borderRadius: '15px'
                            },
                            '&.MuiSlider-thumb': {
                              display: 'none',
                            },
                            '& input[type="range"]': {
                              WebkitAppearance: 'slider-vertical',
                            },
                          }}
                          orientation='vertical'
                          value={J.progress}
                          valueLabelDisplay='auto'
                          disabled={true}
                        />
                      </div>

                      {/* Activities */}
                      <div className='col-10 offset-1' style={{ 
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'end', // Dikey ortalama
                        alignItems: 'center',     // Yatay ortalama
                        paddingLeft: '20px',
                        paddingRight: '20px'
                      }}>
                        <div className='hide-scroll' style={{ 
                          maxHeight: '100%',
                          overflow: 'auto',
                          width: '100%',
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '20px',           // Aktiviteler arası boşluk
                          padding: '20px 0'      // Üst ve alttan padding
                        }}>
                          {J.activityList.map((Activity: any) => (
                            <div key={Activity.activityId} style={{
                              display: 'flex',
                              flexDirection: 'column',
                              alignItems: 'center',
                              gap: '10px',
                              position: 'relative' // Parent'a relative position ekliyoruz
                            }}>
                              {J.flowType === 1 && (
                                <Box
                                  sx={{
                                    background: "#EE3468",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    padding: 0.5,
                                    borderRadius: 15,
                                    position: "absolute", // Absolute position
                                    right: 150,            // Sağa yapıştır
                                    top: -8,             // Yukarı al
                                    zIndex: 10,          // En üstte göster
                                  }}
                                >
                                  <LockIcon fontSize='small' />
                                </Box>
                              )}
                              <div className={
                                Activity.activityType === 0
                                  ? 'rounded-circle border border-warning border-4 bg-white'
                                  : Activity.activityType === 2
                                  ? 'rounded-circle border border-secondary border-4 bg-white'
                                  : Activity.activityType === 1 && Activity.gameType === 0
                                  ? 'rounded-circle border border-primary border-4 bg-primary'
                                  : Activity.activityType === 1 && Activity.gameType === 1
                                  ? 'rounded-circle border border-danger border-4 bg-danger'
                                  : Activity.activityType === 1 && Activity.gameType === 2
                                  ? 'rounded-circle border border-success border-4 bg-success'
                                  : 'rounded-circle border border-info border-4 bg-info'
                              }>
                                <Avatar
                                  variant="circular"
                                  src={Activity.activityIcon}
                                  sx={{
                                    width: "60px",
                                    height: "60px",
                                    '& img': {
                                      objectFit: 'contain',
                                    }
                                  }}
                                >
                                  <Avatar
                                    variant="circular"
                                    src="/media/stock/image.png"
                                    sx={{
                                      width: "60px",
                                      height: "60px",
                                      '& img': {
                                        objectFit: 'contain',
                                      }
                                    }}
                                  />
                                </Avatar>
                              </div>
                              <Typography style={{ fontSize: '16px', textAlign: 'center' }}>
                                {Activity.activityTitle}
                              </Typography>
                            </div>
                          ))}
                        </div>
                      </div>

                      {/* Right Success Rate Slider */}
                      <div className='col-1' style={{ 
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        position: 'absolute',
                        right: 0,
                        zIndex: 1
                      }}>
                        <Avatar 
                          variant='rounded' 
                          src='/media/journey/mortarboard_edited.png' 
                          sx={{ 
                            height: "auto", 
                            width: "25px", 
                            objectFit: "contain"
                          }} 
                        />
                        <Slider
                          sx={{
                            '&.Mui-disabled': {
                              color: '#ED1C77',
                              height: '90%',
                              marginTop: '10px',
                              width: '25%',
                              borderRadius: '15px'
                            },
                            '&.MuiSlider-thumb': {
                              display: 'none',
                            },
                            '& input[type="range"]': {
                              WebkitAppearance: 'slider-vertical',
                            },
                          }}
                          orientation='vertical'
                          value={J.successRate}
                          valueLabelDisplay='auto'
                          disabled={true}
                        />
                      </div>
                    </div>
                  </CardContent>

                  {/* Footer */}
                  <div style={{
                    minHeight: '56px',
                    background: 'black',
                    borderRadius: '0px 0px 15px 15px',
                    borderTop: "2px solid #707070"
                  }}>
                    <div className='w-100 row d-flex justify-content-around'>
                      {/* Time Button */}
                      <div className='col-2 p-0 m-0 text-center'>
                        <Tooltip title={formatDate(J.startDate)}>
                          <IconButton 
                            onClick={() => {
                              setShowTimePreview(true)
                              setJourneyTime({ startDate: J.startDate, endDate: J.endDate })
                            }}
                            style={{
                              mixBlendMode: J.chatIsOn ? 'initial' : 'luminosity',
                            }}
                          >
                            <Avatar 
                              variant="rounded" 
                              src={
                                J.statusId === 1 ? '/media/journey/hourglass.gif' :
                                J.statusId === 2 ? '/media/journey/live.gif' :
                                '/media/journey/finish-line.gif'
                              } 
                            />
                          </IconButton>
                        </Tooltip>
                      </div>

                      {/* Leaderboard Button */}
                      <div className='col-2 p-0 m-0 text-center'>
                        <Tooltip title="Leader Board">
                          <IconButton 
                            onClick={() => {
                              setShowLeader(true)
                              setJourneyName(J.journeyName)
                            }}
                            disabled={!['STARTED', 'FINISHED', 'READY'].includes(J.journeyStatus)}
                          >
                            <Avatar variant="rounded" src="/media/journey/champion.gif" />
                          </IconButton>
                        </Tooltip>
                      </div>

                      {/* Award Button */}
                      <div className='col-2 p-0 m-0 text-center'>
                        <Tooltip title="Award">
                          <IconButton 
                            onClick={() => {
                              setShowAward(true)
                              setJourneyName(J.journeyName)
                              setAwardDetail({
                                journeyAwardMediaText: J.journeyAwardMediaText,
                                journeyAwardMediaType: J.journeyAwardMediaType,
                                journeyAwardMediaUrl: J.journeyAwardMediaUrl,
                                journeyAwardWinners: J.journeyAwardWinners
                              })
                            }}
                            disabled={J.journeyAwardWinners === 0}
                            style={{
                              mixBlendMode: J.journeyAwardWinners !== 0 ? 'initial' : 'luminosity',
                            }}
                          >
                            <Avatar variant="rounded" src="/media/journey/gift.gif" />
                          </IconButton>
                        </Tooltip>
                      </div>

                      {/* Chat Button */}
                      <div className='col-2 p-0 m-0 text-center'>
                        <Tooltip title="Chat">
                          <IconButton 
                            onClick={() => {
                              setShowChat(true)
                              setJourneyName(J.journeyName)
                              setJourneyChatIsOn(J.chatIsOn === "On")
                              setJourneyChatDisable(J.statusId === 3)
                            }}
                            disabled={J.statusId === 0 || J.statusId === 4}
                            style={{
                              mixBlendMode: J.chatIsOn === "On" ? 'initial' : 'luminosity',
                            }}
                          >
                            <Avatar variant="rounded" src="/media/journey/chat.gif" />
                            {J.unReadedMessage && (
                              <div style={{
                                height: '17px',
                                minWidth: '17px',
                                borderRadius: '15px',
                                background: 'red',
                                fontSize: '11px',
                                position: 'absolute',
                                zIndex: 2,
                                marginLeft: '52px',
                              }}>
                                {J.unreadMessagesCount}
                              </div>
                            )}
                          </IconButton>
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                </Card>
              ))}
            </Box>
          )}
        </Box>

        {/* Modals */}
        <JourneyTimePreview
          showTimePreview={showTimePreview}
          setShowTimePreview={setShowTimePreview}
          journeyTime={journeyTime}
        />

        <LeaderBoard
          show={showLeader}
          setShow={setShowLeader}
          journeyId={selectQuestion[0]}
        />

        <JourneyAwardPreview
          show={showAward}
          setShow={setShowAward}
          journeyId={selectQuestion[0]}
          journeyName={journeyName}
          awardDetail={awardDetail}
        />

        <Chat
          show={showChat}
          setShow={setShowChat}
          journeyId={selectQuestion[0]}
          journeyName={journeyName}
          handleMessageChange={(e: any) => setMessage(e.target.value)}
          handleSendMessage={() => {
            if (socket && socket.readyState === WebSocket.OPEN && message.trim() !== '') {
              socket.send(JSON.stringify({
                action: 'sendMessage',
                message: message,
                journeyId: selectQuestion[0],
                token: LoginState.loginData.access_token,
              }))
              setMessage('')

            }
          }}
          messages={messages}
          message={message}
          setMessages={setMessages}
          journeyChatIsOn={journeyChatIsOn}
          setJourneyChatIsOn={setJourneyChatIsOn}
          journeyUnReadedMessage={journeyUnReadedMessage}
          setJourneyUnReadedMessage={setJourneyUnReadedMessage}
          journeyChatDisable={journeyChatDisable}
        />

        {/* Warning Modal */}
        <Dialog
          open={openWarnModal}
          TransitionComponent={Transition}
          keepMounted
          PaperProps={{
            style: {
              backgroundColor: journeyStatus === 1 ? '#0288D1' : '#ED6C02',
            },
          }}
        >
          <DialogTitle>
            <Typography color="white" variant="h5">
              {journeyStatus === 1 ? (
                <InfoOutlinedIcon fontSize="large" />
              ) : (
                <WarningAmberIcon fontSize="large" />
              )}{' '}
              {journeyStatus === 1 ? 'Info' : 'Warning'}
            </Typography>
          </DialogTitle>
          <DialogContent>
            <DialogContentText sx={{ color: 'white' }}>
              {journeyStatus === 0 && 'This operation will PAUSE selected Journey. Proceed?'}
              {journeyStatus === 1 && 'This operation will set Journey status to READY. Proceed?'}
              {journeyStatus === 4 && 'This operation will DELETE the selected Journey. This cannot be undone. Proceed?'}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                // Handle status change
                handleStatusChange()
                setOpenWarnModal(false)
                // Implement status change logic here
              }}
              sx={{ color: 'white' }}
              disabled={loadingStatus}
            >
              Yes
            </Button>
            <Button
              onClick={() => {
                setOpenWarnModal(false)
                setJourneyStatus(-1)
              }}
              sx={{ color: 'white' }}
            >
              No
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  )
}

export default Journeys