import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import ChangePlan from './Components/ChangePlan'
import CustomInfo from './Components/CustomInfo'
import axios from 'axios'
import { useSelector } from 'react-redux'
import { ShowGdpr } from './Components/ShowGdpr'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import {
  Avatar,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControlLabel,
  Link,
  MenuItem,
  Slide,
  Switch,
  TextField,
  Tooltip,
  styled,
} from '@mui/material'
import MainContacts from './Components/MainContacts'
import EditNotes from './Components/EditNotes'
import EditBillingInfo from './Components/EditBillingInfo'
import MobixaCategory from './Components/MobixaCategory'
import Loading from '../../../_metronic/layout/components/Loading'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Unstable_Grid2'
import { ChartContainer, BarPlot } from '@mui/x-charts'
import CustomerAddModal1 from './Components/CustomerAddModal1'
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp'
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion'
import MuiAccordionSummary, { AccordionSummaryProps } from '@mui/material/AccordionSummary'
import MuiAccordionDetails from '@mui/material/AccordionDetails'
import { enqueueSnackbar } from 'notistack'
import React from 'react'
import { TransitionProps } from '@mui/material/transitions'

//Icons
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import WarningAmberIcon from '@mui/icons-material/WarningAmber'
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';


const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction='up' ref={ref} {...props} />
})

const Android12Switch = styled(Switch)(({ theme }) => ({
  padding: 8,
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    '&:before, &:after': {
      content: '""',
      position: 'absolute',
      top: '50%',
      transform: 'translateY(-50%)',
      width: 16,
      height: 16,
    },
    '&:before': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M21,7L9,19L3.5,13.5L4.91,12.09L9,16.17L19.59,5.59L21,7Z"/></svg>')`,
      left: 12,
    },
    '&:after': {
      backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 24 24"><path fill="${encodeURIComponent(
        theme.palette.getContrastText(theme.palette.primary.main)
      )}" d="M19,13H5V11H19V13Z" /></svg>')`,
      right: 12,
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: 16,
    height: 16,
    margin: 2,
  },
}))

const AccordionMui = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  /* border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  }, */
  '&:before': {
    display: 'none',
  },
}))

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    sx={{ height: 20 }}
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  /*  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)', */
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}))

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  /*   borderTop: '1px solid rgba(0, 0, 0, .125)', */
}))

const CustomerProfile = () => {
  const location: any = useLocation()
  const navigate = useNavigate()
  const customerProfile = useSelector((state: any) => state.Login.customerProfile)
  const [isSubscribed, setIsSubscribed] = useState(false)
  const [searchParams] = useSearchParams()
  const [customerIdGetLink] = useState(searchParams.get('id'))
  const [tabValue, setTabValue] = useState(0)

  const [userRole, setUserRole] = useState<any>([])
  useEffect(() => {
    let role = customerProfile?.role
    setUserRole(role)
  }, [])
  //Permission Start
  let roleId = [0, 1, 5]
  let itRoleId = [5]
  let roleIdBasicInfoEdit = [0]
  let roleIdTabBilling = [0, 1, 6]
  let roleIdTabPlan = [0, 1]
  let roleIdTabPlanEdit = [0, 1]
  let roleIsValidBasicInfo = userRole?.some((role: any) => roleId.includes(role.roleId))
  let roleIsValidBasicInfoEdit = userRole?.some((role: any) =>
    roleIdBasicInfoEdit.includes(role.roleId)
  )
  let roleIsValidBilling = userRole?.some((role: any) => roleIdTabBilling.includes(role.roleId))
  let roleIsValidPlan = userRole?.some((role: any) => roleIdTabPlan.includes(role.roleId))
  let roleIsValidPlanEdit = userRole?.some((role: any) => roleIdTabPlanEdit.includes(role.roleId))
  let roleIsValidIT = userRole?.some((role: any) => itRoleId.includes(role.roleId))

  useEffect(() => {
    if (userRole.length > 0) {
      if (!userRole?.some((role: any) => roleId.includes(role.roleId))) {
        navigate("/")
      }
    }
  }, [userRole])

  //Permission end
  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue)
  }

  interface TabPanelProps {
    children?: React.ReactNode
    index: number
    value: number
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props

    return (
      <div
        role='tabpanel'
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    )
  }

  function a11yProps(index: number) {
    console.log(index)
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }
  const handleChange = (event: any) => {
    const el = document.getElementById('Block1')

    if (event.target.checked) {
      el?.classList.remove('d-none')
    } else {
      el?.classList.add('d-none')
    }
    setIsSubscribed((current) => !current)
  }
  const handleChangePlan = (event) => {
    setSelectedPlan(event.target.value);
    setPlanChanged(true)
  };
  const [isBlocking, setIsBlocked] = useState(false)

  const handleBlock = (event: any) => {
    const el = document.getElementById('blockbuton1')

    if (event.target.checked) {
      el?.classList.remove('disabled')
    } else {
      el?.classList.add('disabled')
    }
    setIsBlocked((current) => !current)
  }
  const subId = (e: any) => {

    if (e === 1) {
      return (
        <Chip
          label='Demo'
          variant='outlined'
          sx={{ overflow: 'visible', maxWidth: '180px', fontSize: '13px', color: "#707070" }}
        />
      )
    }
    if (e === 2) {
      return (
        <Chip
          label='Starter'
          variant='outlined'
          color='primary'
          sx={{ overflow: 'visible', maxWidth: '180px', fontSize: '13px' }}
        />
      )
    }
    if (e === 3) {
      return (
        <Chip
          label='Challenger'
          variant='outlined'
          color='secondary'
          sx={{ overflow: 'visible', maxWidth: '180px', fontSize: '13px' }}
        />
      )
    }
    if (e === 4) {
      return (
        <Chip
          label='Pro'
          variant='outlined'
          color='error'
          sx={{ overflow: 'visible', maxWidth: '180px', fontSize: '13px' }}
        />
      )
    }
  }

  const statusId = (e: any) => {
    if (e === 0) {
      return (
        <Chip
          label='Blocked'
          variant='outlined'
          color='secondary'
          sx={{ overflow: 'visible', maxWidth: '90px', fontSize: '13px' }}
        />
      )
    } else {
      return (
        <Chip
          label='Open'
          variant='outlined'
          color='success'
          sx={{ overflow: 'visible', maxWidth: '90px', fontSize: '13px' }}
        />
      )
    }
  }
  const statusIdList = (e: any) => {
    if (e === 0) {
      return <span>Blocked</span>
    } else {
      return <span>Open</span>
    }
  }
  const Payment = (e: any) => {
    if (e === 'true') {
      return <span>Annualy</span>
    }
    if (e === 'false') {
      return <span>Monthly</span>
    }
  }
  function deleteNote(noteId: any) {
    axios
      .post(`https://plusapi.mobixa.net/customer/editCustomer/deleteNote/${noteId}`, '', {
        headers: {
          accept: 'application/json',
          Authorization: `Bearer ${LoginState.loginData.access_token}`,
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      })
      .then((response) => {
        // expected the setProducts to be filled with the return of this request
        setfirst(!first)
      })
  }
  const Gdpr = (e: any) => {
    if (e === true) {
      return <span className=''>Compulsory</span>
    } else {
      return <span className=''>Not Compulsory</span>
    }
  }
  const [key, setKey] = useState('home')
  const LoginState = useSelector((state: any) => state.Login)
  const [products, setProducts] = useState<any>()
  const [contacts, setContacts] = useState<any>([])
  const [openWarnModalPlan, setOpenWarnModalPlan] = useState(false)
  const [openWarnModalPlanFirst, setOpenWarnModalPlanFirst] = useState(false)
  const handleClosePlan = () => setOpenWarnModalPlan(false)
  const [openEditCustomerModal, setOpenEditCustomerModal] = useState<any>(false)
  /*  const [plans, setPlans] = useState([])
 
   useEffect(() => {
     axios
       .get(`${process.env.REACT_APP_API_URL}/customer/getSubscriptionList`, {
         headers: {Authorization: `Bearer ${LoginState.loginData.access_token}`},
       })
       .then((res) => {
         setPlans(res.data)
       })
       .catch((err) => {})
   }, []) */

  const ax = axios.create({ headers: { Accept: 'application/json' } })

  const [isLoading, setisLoading] = useState(false)
  const [user, setuser] = useState<any>()
  async function test() {
    let customerId = customerProfile?.customerId
    setuser(customerId)
    /* await ax
      .get(`${process.env.REACT_APP_API_URL}/user/getUserWithJWT`, {
        headers: {Authorization: `Bearer ${LoginState.loginData.access_token}`},
      })
      .then((response) => {
        // expected the setProducts to be filled with the return of this request
        setuser(response.data.customerId)
      }) */
    await ax
      .get(
        `${process.env.REACT_APP_API_URL}/customer/getCustomerDetail/${customerIdGetLink || customerId
        }`,
        {
          headers: { Authorization: `Bearer ${LoginState.loginData.access_token}` },
        }
      )
      .then((response) => {
        // expected the setProducts to be filled with the return of this request
        setProducts(response.data)
        setContacts(response.data.contacts)
        setisLoading(false)
      })
      .catch((err) => {
        setisLoading(false)
      })
  }

  const [first, setfirst] = useState(false)

  useEffect(() => {
    setisLoading(true)
    test()
  }, [LoginState.loginData, first])

  const [isActivePro, setisActivePro] = useState(true)

  const handlePro = () => {
    // 👇️ toggle isActivePro state on click
    setisActivePro((current) => !current)
    setisActivechalenger(true)
    setisActiveStarter(true)
    setisActiveFree(true)
  }
  const [isActivechalenger, setisActivechalenger] = useState(true)

  const handlechalenger = () => {
    // 👇️ toggle isActivePro state on click
    setisActivechalenger((current) => !current)
    setisActivePro(true)
    setisActiveStarter(true)
    setisActiveFree(true)
  }
  const [isActiveStarter, setisActiveStarter] = useState(true)

  const handleStarter = () => {
    // 👇️ toggle isActivePro state on click
    setisActivechalenger(true)
    setisActivePro(true)
    setisActiveStarter((current) => !current)
    setisActiveFree(true)
  }
  const [isActiveFree, setisActiveFree] = useState(true)

  const handleFree = () => {
    // 👇️ toggle isActivePro state on click
    setisActivechalenger(true)
    setisActivePro(true)
    setisActiveStarter(true)
    setisActiveFree((current) => !current)
  }
  const [proNum, setproNum] = useState('')
  useEffect(() => {
    /*  if (products?.subscriptionId > 3) {
       setproNum(products?.subscriptionNumberOfUsers)
     } */
    function test(e: number) {
      setSelectedPlan(e)
      /* if (e == 0) {
        handleFree()
      }
      if (e == 1) {
        handleStarter()
      }
      if (e == 2) {
        handlechalenger()
      }
      if (e >= 3) {
        handlePro()
      } */
    }
    test(products?.subscriptionId)
  }, [products])

  const [showGdpr, setshowGdpr] = useState(false)
  const defaultSrc = toAbsoluteUrl('/media/svg/image.svg')
  const companyImage = products?.avatarPath


  const [hover, setHover] = useState('')
  //Plan
  const [plan, setPlan] = useState<any>()
  const [selectedPlan, setSelectedPlan] = useState<any>()
  const [planChanged, setPlanChanged] = useState<any>(false)

  useEffect(() => {
    setPlanChanged(false)
    const fetchData = async () => {
      await axios
        .get(`${process.env.REACT_APP_API_URL}/customer/getCustomerPlan`, {
          params: {
            customerId: customerIdGetLink,
          },
          headers: {
            accept: 'application/json',
            Authorization: `Bearer ${LoginState.loginData.access_token}`,
          },
        })
        .then((res) => {
          setPlan(res.data)
        })
        .catch((err) => { })
    }
    fetchData()
  }, [first])

  //Free mobixacontent
  const [selected, setSelected] = useState<any>([])
  const [categories, setCategories] = useState<any>([])
  const [mobixaContent, setMobixaContent] = useState({
    editCategoryButton: false,
    credit: 0,
    checkedTotalQuestionCount: 0,
  })

  useEffect(() => {
    const res = ax
      .get(`${process.env.REACT_APP_API_URL}/customer/getCheckedCategories/${customerIdGetLink}`, {
        headers: { Authorization: `Bearer ${LoginState.loginData.access_token}` },
      })
      .then((response) => {
        // expected the setProducts to be filled with the return of this request
        setMobixaContent((prevState) => ({
          ...prevState,
          editCategoryButton: response.data.editCategoryButton,
          credit: response.data.credit,
          checkedTotalQuestionCount: response.data.checkedTotalQuestionCount,
        }))
        setCategories(response.data.rootCategory)
      })
  }, [first])

  const planOptions = [
    { value: 0, label: '' },
    { value: 1, label: 'Demo' },
    { value: 2, label: 'Starter' },
    { value: 3, label: 'Challenger' },
    { value: 4, label: 'Pro' }
  ];

  const handleSave = () => {
    axios.post(
      `${process.env.REACT_APP_API_URL}/customer/editCustomer/subscription/${customerIdGetLink}`,
      {
        subscriptionId: selectedPlan,
        /*         paymentFrequency: mothannual, */
        /*   numberOfUsers: user, */
        /*   ai: ai,
          freeQuestion: questions,
          freeKnowledgeCard: learningCard, */
      },
      {
        headers: {
          accept: 'application/json',
          Authorization: `Bearer ${LoginState.loginData.access_token}`,
          'Content-Type': 'application/json',
        },
      }
    )
      .then((response) => {
        // expected the setProducts to be filled with the return of this request
        setOpenWarnModalPlanFirst(false)
        enqueueSnackbar('Successfully saved', { variant: 'success' })
        setfirst(!first)
      })
      .catch((err) => {

        setOpenWarnModalPlanFirst(false)
        if (err?.response?.data?.detail?.errorMessage === "MAX NUMBER OF USERS REACHED") {

          setTimeout(() => {
            setOpenWarnModalPlan(true)
            enqueueSnackbar('Saved Error', { variant: 'error' })
          }, 500);
        }

      })
  }
  /*   const uData = [100, 50, 20, 270, 1000, 290, 220, 100, 50, 20, 270, 100]
  const xLabels = [
    'Page A',
    'Page B',
    'Page C',
    'Page D',
    'Page E',
    'Page F',
    'Page G',
    'Page s',
    'Page b',
    'Page r',
    'Page j',
    'Page k',
  ]
 */

  function formatCreatedDate(dateString) {
    if (!dateString) return '';

    const createdDate = new Date(dateString);
    return new Intl.DateTimeFormat('tr-TR', {
      year: 'numeric', month: '2-digit', day: '2-digit',
      hour: '2-digit', minute: '2-digit', second: '2-digit',
      hour12: false
    }).format(createdDate);
  }

  const [serviceLoading, setServiceLoading] = useState(false)
  const [serviceCreateLoading, setServiceCreateLoading] = useState(false)
  const [serviceCreateData, setServiceCreateData] = useState(null)
  const [serviceModalOpen, setServiceModalOpen] = useState(false)
  const [accessKey, setAccessKey] = useState<any>(null)

  const getServiceApi = () => {
    setServiceLoading(true)
    axios.get(
      `${process.env.REACT_APP_API_URL}/customer/getApiKey`,
      {
        headers: {
          accept: 'application/json',
          Authorization: `Bearer ${LoginState.loginData.access_token}`,
          'Content-Type': 'application/json',
        },
      }
    )
      .then((response) => {
        // expected the setProducts to be filled with the return of this request
        setAccessKey(response.data?.accessKey)
        setServiceLoading(false)
      })
      .catch((err) => {
        setServiceLoading(false)

      })
  }
  const createServiceApi = () => {
    setServiceModalOpen(true)
    setServiceCreateLoading(true)
    axios.get(
      `${process.env.REACT_APP_API_URL}/customer/createApiKey`,
      {
        headers: {
          accept: 'application/json',
          Authorization: `Bearer ${LoginState.loginData.access_token}`,
          'Content-Type': 'application/json',
        },
      }
    )
      .then((response) => {
        // expected the setProducts to be filled with the return of this request
        setTimeout(() => {
          setServiceCreateData(response.data)
          setServiceCreateLoading(false)
        }, 1500);
      })
      .catch((err) => {
        setServiceCreateLoading(false)

      })
  }

  useEffect(() => {
    if (tabValue === 6) {
      getServiceApi()
    }
  }, [tabValue])
  const [copyIsSuccess, setCopyIsSuccess] = useState({ accessKey: false, secretKey: false })
  const copyToClipboard = async (text) => {
    if ('clipboard' in navigator) {
      setCopyIsSuccess((prevState) => {
        return { ...prevState, accessKey: true };
      })
      setTimeout(() => {
        setCopyIsSuccess((prevState) => {
          return { ...prevState, accessKey: false };
        })
      }, 1000);
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  };
  const copyToClipboard1 = async (text) => {
    if ('clipboard' in navigator) {
      setCopyIsSuccess((prevState) => {
        return { ...prevState, secretKey: true };
      })
      setTimeout(() => {
        setCopyIsSuccess((prevState) => {
          return { ...prevState, secretKey: false };
        })
      }, 1000);
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  };

  const [hoverButton, setHoverButton] = useState(false)
  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <>
          <div className='mb-5 d-flex justify-content-between align-items-center'>
            <span className='h5 text-white '>Customers / Profile</span>
            <Box
              display='flex'
              justifyContent='flex-end'
              alignItems='center'
              alignContent='center'
            /*     sx={{ height: 50 }} */
            >
              <div style={{ marginRight: 90 }}>
                {
                  <Tooltip
                    arrow
                    title='Customer List'
                    placement='top'
                    onMouseEnter={() => {
                      setHoverButton(true)
                    }}
                    onMouseLeave={() => {
                      setHoverButton(false)
                    }}
                    style={{ background: hoverButton ? '#D5D5D5 ' : 'white' }}
                  >
                    <IconButton
                      aria-label='By Activity'
                      onClick={(e) => navigate(`/administration/customers`)}
                      size='small'
                      sx={{
                        background: 'white',
                        color: hoverButton ? 'black' : '',
                        marginLeft: '7px',
                      }}
                    >
                      <FormatListBulletedOutlinedIcon sx={{ fontSize: '30px' }} />
                    </IconButton>
                  </Tooltip>
                }
              </div>
            </Box>
            {/* <button className='btn p-0' style={{ marginRight: 80 }} onClick={(e) => navigate(`/administration/customers`)}>
              <span className='svg-icon svg-icon-3x'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='512'
                  height='512'
                  viewBox='0 0 512 512'
                >
                  <line
                    x1='224'
                    y1='184'
                    x2='352'
                    y2='184'
                    style={{
                      fill: 'none',
                      stroke: '#F8F9FA',
                      strokeLinecap: 'round',
                      strokeLinejoin: 'round',
                      strokeWidth: '32px',
                    }}
                  />
                  <line
                    x1='224'
                    y1='256'
                    x2='352'
                    y2='256'
                    style={{
                      fill: 'none',
                      stroke: '#F8F9FA',
                      strokeLinecap: 'round',
                      strokeLinejoin: 'round',
                      strokeWidth: '32px',
                    }}
                  />
                  <line
                    x1='224'
                    y1='327'
                    x2='352'
                    y2='327'
                    style={{
                      fill: 'none',
                      stroke: '#F8F9FA',
                      strokeLinecap: 'round',
                      strokeLinejoin: 'round',
                      strokeWidth: '32px',
                    }}
                  />
                  <path
                    d='M448,258c0-106-86-192-192-192S64,152,64,258s86,192,192,192S448,364,448,258Z'
                    style={{
                      fill: 'none',
                      stroke: '#F8F9FA',
                      strokeMiterlimit: 'round',
                      strokeWidth: '32px',
                    }}
                  />
                  <circle
                    cx='168'
                    cy='184'
                    r='8'
                    style={{
                      fill: 'none',
                      stroke: '#F8F9FA',
                      strokeLinecap: 'round',
                      strokeLinejoin: 'round',
                      strokeWidth: '32px',
                    }}
                  />
                  <circle
                    cx='168'
                    cy='257'
                    r='8'
                    style={{
                      fill: 'none',
                      stroke: '#F8F9FA',
                      strokeLinecap: 'round',
                      strokeLinejoin: 'round',
                      strokeWidth: '32px',
                    }}
                  />
                  <circle
                    cx='168'
                    cy='328'
                    r='8'
                    style={{
                      fill: 'none',
                      stroke: '#F8F9FA',
                      strokeLinecap: 'round',
                      strokeLinejoin: 'round',
                      strokeWidth: '32px',
                    }}
                  />
                </svg>
              </span>
            </button> */}
          </div>
          <div
            className='card rounded mb-5 mb-xl-10'
            style={{ position: 'initial', height: '213px' }}
          >
            <div className='card-body  pb-0'>
              <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
                <div className='col-3 ms-5 me-7 mb-4 d-flex justify-items-center align-items-center'>
                  <label className='  text-center ' style={{ width: '240px', height: '80px' }}>
                    {companyImage ? (
                      <Avatar
                        variant='rounded'
                        sx={{
                          width: '205px', height: '68px', background: "black", img: {
                            objectFit: 'contain',
                          }
                        }}

                        src={companyImage}
                      />
                    ) : (
                      <Avatar
                        variant='rounded'
                        sx={{ width: '205px', height: '68px' }}
                        src={toAbsoluteUrl('/media/logos/demo2.png')}
                      />
                    )}
                  </label>
                  <div className='symbol symbol-180px symbol-lg-180px symbol-fixed position-relative'>
                    <div className='image-input image-input-outline ' id='kt_image_1'></div>
                  </div>
                </div>
                <div className='col-9 row '>
                  <div className=' col-6 d-flex justify-content-center  flex-wrap mb-2'>
                    <div className='d-flex flex-column'>
                      <div className='text-center'>
                        <a
                          href='#'
                          className='text-gray-800 text-hover-primary fs-2 fw-bold text-center m-5'
                        >
                          {products?.title}
                        </a>
                      </div>
                      <div className='d-flex'>
                        <div className='text-center fs-6 me-5 mt-5'>
                          Plan
                          <br />
                          {subId(products?.subscriptionId)}
                        </div>
                        <div className='text-center fs-6 ms-5 mt-5'>
                          Status
                          <br />
                          {statusId(products?.statusId)}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div className='col-6 d-flex flex-wrap flex-stack ' style={{ marginTop: '-20px' }}>
                    <Grid container marginTop={7}>
                      {products && (
                        <Grid
                          xs={6}
                          container
                          justifyContent={'center'}
                          textAlign={'center'}
                          direction={'column'}
                        >
                          <Typography color={'#20C997'} variant='h6'>
                            Activity
                          </Typography>
                          <Typography
                            color={'#20C997'}
                            variant='h5'
                            style={{ marginBottom: '-50px' }}
                          >
                            %76
                          </Typography>
                          <ChartContainer
                            width={200}
                            height={180}
                            sx={{ padding: '0px', margin: '0px' }}
                            colors={['#20C997']}
                            series={[{ data: products?.activityUData || [], label: 'uv', type: 'bar' }]}
                            xAxis={[{ scaleType: 'band', data: products?.activityXLabel || [] }]}
                          >
                            <BarPlot />
                          </ChartContainer>
                        </Grid>
                      )}
                      {products && (
                        <Grid
                          xs={6}
                          container
                          justifyContent={'space-between'}
                          textAlign={'center'}
                          direction={'column'}
                        >
                          <Typography color={'#ED6C02'} variant='h6'>
                            Impact
                          </Typography>
                          <Typography
                            color={'#ED6C02'}
                            variant='h5'
                            style={{ marginBottom: '-50px' }}
                          >
                            %76
                          </Typography>
                          <ChartContainer
                            width={200}
                            height={180}
                            colors={['#ED6C02']}
                            series={[{ data: products?.impactUData || [], label: 'uv', type: 'bar' }]}
                            xAxis={[{ scaleType: 'band', data: products?.impactXLabel|| [] }]}
                          >
                            <BarPlot />
                          </ChartContainer>
                        </Grid>
                      )}
                    </Grid>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs 
                value={tabValue} 
                onChange={handleTabChange} 
                aria-label='basic tabs example'
                sx={{
                  marginTop: 2,
                  '& .MuiTab-root': {
                    fontSize: '15px',
                  },
                }}
              >
                {roleIsValidIT ? (
                  <Tab 
                    label='Service Api' 
                    {...a11yProps(6)} 
                    sx={{ fontSize: 14 }} 
                    disabled={!products?.apiIsOn}
                  />
                ) : [
                  <Tab key="tab-0" label='Basic Info' {...a11yProps(0)} sx={{ fontSize: 14 }} />,
                  roleIsValidPlan && <Tab key="tab-1" label='Plan' {...a11yProps(1)} sx={{ fontSize: 14 }} />,
                  roleIsValidBilling && <Tab key="tab-2" label='Billing' {...a11yProps(2)} sx={{ fontSize: 14 }} />,
                  roleIsValidPlan && <Tab key="tab-3" label='Contacts' {...a11yProps(3)} sx={{ fontSize: 14 }} />,
                  roleIsValidPlanEdit && <Tab key="tab-4" label='Notes' {...a11yProps(4)} sx={{ fontSize: 14 }} />,
                  <Tab key="tab-5" label='Free Mobixa Contents' {...a11yProps(5)} sx={{ fontSize: 14 }} />,
                  roleIsValidPlan && <Tab key="tab-6" label='Service Api' {...a11yProps(6)} sx={{ fontSize: 14 }} disabled={!products?.apiIsOn} />
                ].filter(Boolean)}
              </Tabs>
            </Box>
            {roleIsValidIT ? (
              <TabPanel value={tabValue} index={0}>
              {
                serviceLoading ?
                  <Box width={"100%"} height={"100%"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                    <CircularProgress size={26} color='primary' />
                  </Box>
                  :
                  <Grid container>
                    <Grid xs={12} >
                      <Box >
                        <Typography fontSize={14} >Our API helps you to transfer data for full-automatic user creation in Mobixa Database Your secret API keys are listed below. Please note that will NOT display your secret API keys again. You can always produce a new one if you want. Do not share your API key with others, or expose it in the browser or other client-side code. In order to protect the security of your account, MobixaPlus may also disable any API key that we’ve found has leaked publicly. Please save this secret key somewhere safe and accessible. For security reasons, you won’t be able to view it again through your MobixaPlus account. If you lose this secret key, you’ll need to generate a new one. You can find the API documentation
                          <Link href={`${process.env.REACT_APP_API_URL === "https://plusapitest.mobixa.net" ? (process.env.REACT_APP_API_URL + "/mobixaDocs") : (process.env.REACT_APP_API_URL + "/docs")}`} sx={{ cursor: "pointer" }}>here</Link> </Typography>
                      </Box>
                    </Grid>
                    <Grid xs={3} mt={2}>
                      <Box>
                        <Button variant='contained' size='medium' disabled={serviceCreateLoading} onClick={() => createServiceApi()} >
                          CREATE API KEY
                        </Button>
                      </Box>
                    </Grid>
                    {
                      accessKey &&
                      <Grid xs={9} mt={2}>
                        <Box display={"flex"} alignItems={"center"}>
                          <Typography fontSize={12} fontWeight={"bold"}>Access Key</Typography>
                          <Typography fontSize={12} marginLeft={2}>{accessKey}</Typography>
                        </Box>
                        <Box display={"flex"} alignItems={"center"} marginTop={3}>
                          <Typography fontSize={12} fontWeight={"bold"} marginRight={2}>Secret Key</Typography>
                          <Typography fontSize={12} >****************************</Typography>
                        </Box>
                      </Grid>
                    }
                  </Grid>
              }


              </TabPanel>
            ) : (
              <>
              {
                //#region  Basic İnfo
                <TabPanel value={tabValue} index={0}>
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                      {/* <Grid xs={12}>
                        <Typography variant='h4' fontWeight={'bold'} component='h2'>
                          Customer Info
                        </Typography>
                        <Divider style={{marginTop: '5px'}} />
                      </Grid> */}
                      <Grid xs={3} display={"flex"} justifyContent={"space-between"}>
                        <Typography variant='h5' color='text.secondary' component='h2'>
                          Organisation
                        </Typography>
                        <Typography variant='h5' color='text.secondary' component='h2'>
                          :
                        </Typography>
                      </Grid>
                      <Grid xs={7}>
                        <Typography variant='h5' fontWeight='bold' component='h2'>
                          {products?.title.toUpperCase()}
                        </Typography>
                      </Grid>
                      <Grid xs={3} display={"flex"} justifyContent={"space-between"}>
                        <Typography variant='h5' color='text.secondary' component='h2'>
                          Plan
                        </Typography>
                        <Typography variant='h5' color='text.secondary' component='h2'>
                          :
                        </Typography>
                      </Grid>
                      <Grid xs={7}>
                        <Typography variant='h5' fontWeight='bold' component='h2'>
                          {products?.subscriptionName}
                        </Typography>
                      </Grid>
                      <Grid xs={3} display={"flex"} justifyContent={"space-between"}>
                        <Typography variant='h5' color='text.secondary' component='h2'>
                          Status
                        </Typography>
                        <Typography variant='h5' color='text.secondary' component='h2'>
                          :
                        </Typography>
                      </Grid>
                      <Grid xs={7}>
                        <Typography variant='h5' fontWeight='bold' component='h2'>
                          {statusIdList(products?.statusId)}
                        </Typography>
                      </Grid>
                      <Grid xs={3} display={"flex"} justifyContent={"space-between"}>
                        <Typography variant='h5' color='text.secondary' component='h2'>
                          ID
                        </Typography>
                        <Typography variant='h5' color='text.secondary' component='h2'>
                          :
                        </Typography>
                      </Grid>
                      <Grid xs={7}>
                        <Typography variant='h5' fontWeight='bold' component='h2'>
                          {products?.customerId}
                        </Typography>
                      </Grid>
                      <Grid xs={3} display={"flex"} justifyContent={"space-between"}>
                        <Typography variant='h5' color='text.secondary' component='h2'>
                          Login Address
                        </Typography>
                        <Typography variant='h5' color='text.secondary' component='h2'>
                          :
                        </Typography>
                      </Grid>
                      <Grid xs={7}>
                        <Typography variant='h5' fontWeight='bold' component='h2'>
                          https://plusadmin.mobixa.net/{products?.title}
                        </Typography>
                      </Grid>
                      <Grid xs={3} display={"flex"} justifyContent={"space-between"}>
                        <Typography variant='h5' color='text.secondary' component='h2'>
                          GDPR
                        </Typography>
                        <Typography variant='h5' color='text.secondary' component='h2'>
                          :
                        </Typography>
                      </Grid>
                      <Grid xs={7}>
                        <Typography variant='h5' fontWeight='bold' component='h2'>
                          {Gdpr(products?.GDPRStatement)}{' '}
                          <ShowGdpr
                            show={showGdpr}
                            setShow={setshowGdpr}
                            Gdpr={products?.GDPRText}
                            gdprStatement={products?.GDPRStatement}
                          ></ShowGdpr>
                        </Typography>
                      </Grid>
                      <Grid xs={3} display={"flex"} justifyContent={"space-between"}>
                        <Typography variant='h5' color='text.secondary' component='h2'>
                          Sales Channel
                        </Typography>
                        <Typography variant='h5' color='text.secondary' component='h2'>
                          :
                        </Typography>
                      </Grid>
                      <Grid xs={7}>
                        <Typography variant='h5' fontWeight='bold' component='h2'>
                          {products?.salesChannel}
                        </Typography>
                      </Grid>
                      <Grid xs={3} display={"flex"} justifyContent={"space-between"}>
                        <Typography variant='h5' color='text.secondary' component='h2'>
                          Joined Date
                        </Typography>
                        <Typography variant='h5' color='text.secondary' component='h2'>
                          :
                        </Typography>
                      </Grid>
                      <Grid xs={7}>
                        <Typography variant='h5' fontWeight='bold' component='h2'>
                          {formatCreatedDate(products?.createdDate)}
                        </Typography>
                      </Grid>
                      <Grid xs={3} display={"flex"} justifyContent={"space-between"}>
                        <Typography variant='h5' color='text.secondary' component='h2'>
                          Customer Date
                        </Typography>
                        <Typography variant='h5' color='text.secondary' component='h2'>
                          :
                        </Typography>
                      </Grid>
                      <Grid xs={7}>
                        <Typography variant='h5' fontWeight='bold' component='h2'>
                          {formatCreatedDate(products?.customerDate)}
                        </Typography>
                      </Grid>
                      <Grid xs={3} display={"flex"} justifyContent={"space-between"}>
                        <Typography variant='h5' color='text.secondary' component='h2'>
                          Default Password For New Users
                        </Typography>
                        <Typography variant='h5' color='text.secondary' component='h2'>
                          :
                        </Typography>
                      </Grid>
                      <Grid xs={7}>
                        <Typography variant='h5' fontWeight='bold' color='error' component='h2'>
                          {products?.defaultUserPassword.replace(/\B(?=(\d{3})+(?!\d))/g, ' ')}
                        </Typography>
                      </Grid>
                      <Grid xs={12}>
                        <Divider variant='middle' sx={{ borderTop: '2px solid' }} />
                      </Grid>
                      <Grid
                        xs={12}
                        style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '20px' }}
                      >
                        {/*  <CustomInfo user={products} first={first} setfirst={setfirst} /> */}
                        <Button
                          variant='contained'
                          size='large'
                          disabled={roleIsValidBasicInfo === true ? false : true}
                          onClick={() => {
                            setOpenEditCustomerModal(true)
                          }}
                        >
                          Edit
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                </TabPanel>
                //#endregion
              }
              {
                //#region  Plan
                <TabPanel value={tabValue} index={1}>
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid container alignItems='center' justifyItems='center'>
                      <Grid xs={5} spacing={2} alignItems='center' justifyItems='stretch'>
                        <Grid xs={1}></Grid>
                        <Grid
                          xs={8}
                          container
                          justifyContent='space-between'
                          alignItems='center'
                          paddingRight={3}
                        >
                          <Typography fontSize={18} fontWeight='bold' component='h2'>
                            Public Mode
                          </Typography>

                          <Checkbox
                            size='medium'
                            defaultChecked
                            disabled
                            checked={plan?.quizModule === true}
                            sx={{ '& .MuiSvgIcon-root': { fontSize: 36 } }}
                          />

                          <Divider style={{ marginTop: '5px', width: '100%' }} />
                        </Grid>
                        <Grid xs={3}></Grid>
                        <Grid xs={1}></Grid>
                        <Grid
                          xs={8}
                          container
                          justifyContent='space-between'
                          alignItems='center'
                          paddingRight={3}
                        >
                          <Typography fontSize={18} fontWeight='bold' component='h2'>
                            Quiz
                          </Typography>

                          <Checkbox
                            size='medium'
                            defaultChecked
                            disabled
                            checked={plan?.quizModule === true}
                            sx={{ '& .MuiSvgIcon-root': { fontSize: 36 } }}
                          />

                          <Divider style={{ marginTop: '5px', width: '100%' }} />
                        </Grid>
                        <Grid xs={3}></Grid>
                        <Grid xs={1}></Grid>
                        <Grid
                          xs={8}
                          container
                          justifyContent='space-between'
                          alignItems='center'
                          paddingRight={3}
                        >
                          <Typography fontSize={18} fontWeight='bold' component='h2'>
                            Exam
                          </Typography>

                          <Checkbox
                            size='medium'
                            defaultChecked
                            disabled
                            checked={plan?.exam === true}
                            sx={{ '& .MuiSvgIcon-root': { fontSize: 36 } }}
                          />

                          <Divider style={{ marginTop: '5px', width: '100%' }} />
                        </Grid>
                        <Grid xs={3} />
                        <Grid xs={1}></Grid>
                        <Grid
                          xs={8}
                          container
                          justifyContent='space-between'
                          alignItems='center'
                          paddingRight={3}
                        >
                          <Typography fontSize={18} fontWeight='bold' component='h2'>
                            E-Learn
                          </Typography>

                          <Checkbox
                            size='medium'
                            defaultChecked
                            disabled
                            checked={plan?.eLearnModule === true}
                            sx={{ '& .MuiSvgIcon-root': { fontSize: 36 } }}
                          />

                          <Divider style={{ marginTop: '5px', width: '100%' }} />
                        </Grid>
                        <Grid xs={3}></Grid>
                        <Grid xs={1}></Grid>
                        <Grid
                          xs={8}
                          container
                          justifyContent='space-between'
                          alignItems='center'
                          paddingRight={3}
                        >
                          <Typography fontSize={18} fontWeight='bold' component='h2'>
                            Knowledge Games
                          </Typography>

                          <Checkbox
                            size='medium'
                            defaultChecked
                            disabled
                            checked={plan?.knowledgeGames === true}
                            sx={{ '& .MuiSvgIcon-root': { fontSize: 36 } }}
                          />

                          <Divider style={{ marginTop: '5px', width: '100%' }} />
                        </Grid>
                        <Grid xs={3}></Grid>
                        <Grid xs={1}></Grid>
                        <Grid
                          xs={8}
                          container
                          justifyContent='space-between'
                          alignItems='center'
                          paddingRight={3}
                        >
                          <Typography fontSize={18} fontWeight='bold' component='h2'>
                            Online Chat
                          </Typography>

                          <Checkbox
                            size='medium'
                            defaultChecked
                            disabled
                            checked={plan?.whiteLabel === true}
                            sx={{ '& .MuiSvgIcon-root': { fontSize: 36 } }}
                          />

                          <Divider style={{ marginTop: '5px', width: '100%' }} />
                        </Grid>
                        <Grid xs={3}></Grid>
                        <Grid xs={1}></Grid>
                        <Grid
                          xs={8}
                          container
                          justifyContent='space-between'
                          alignItems='center'
                          paddingRight={3}
                        >
                          <Typography fontSize={18} fontWeight='bold' component='h2'>
                            White Label
                          </Typography>

                          <Checkbox
                            size='medium'
                            defaultChecked
                            disabled
                            checked={plan?.whiteLabel === true}
                            sx={{ '& .MuiSvgIcon-root': { fontSize: 36 } }}
                          />

                          <Divider style={{ marginTop: '5px', width: '100%' }} />
                        </Grid>
                        <Grid xs={3}></Grid>
                        <Grid xs={1}></Grid>
                        <Grid
                          xs={8}
                          container
                          justifyContent='space-between'
                          alignItems='center'
                          paddingRight={3}
                        >
                          <Typography fontSize={18} fontWeight='bold' component='h2'>
                            API Integration
                          </Typography>

                          <Checkbox
                            size='medium'
                            defaultChecked
                            disabled
                            checked={plan?.apiIntegration === true}
                            sx={{ '& .MuiSvgIcon-root': { fontSize: 36 } }}
                          />

                          <Divider style={{ marginTop: '5px', width: '100%' }} />
                        </Grid>
                        <Grid xs={3}></Grid>
                        <Grid xs={1}></Grid>
                        <Grid
                          xs={8}
                          container
                          justifyContent='space-between'
                          alignItems='center'
                        >
                          <Typography fontSize={18} fontWeight='bold' component='h2'>
                            Ai
                          </Typography>
                          <div style={{ textAlign: 'center' }}>
                            <Typography fontSize={18} fontWeight={'bold'} color='black' component='h2'>
                              {plan?.ai}
                            </Typography>
                            <Typography
                              className='text-muted'
                              variant='h6'
                              color='text.secondary'
                              component='h2'
                            >
                              tokens/month
                            </Typography>
                          </div>
                          <Divider style={{ marginTop: '5px', width: '100%' }} />
                        </Grid>
                        <Grid xs={3}></Grid>
                        <Grid xs={1}></Grid>
                        <Grid
                          xs={8}
                          container
                          justifyContent='space-between'
                          alignItems='center'
                          paddingRight={4}
                          height={"60px"}
                        >
                          <Typography fontSize={18} fontWeight='bold' component='h2'>
                            Users
                          </Typography>
                          <Typography fontSize={18} fontWeight={'bold'} color='black' component='h2'>
                            {plan?.maxNumberOfUsers}
                          </Typography>
                          <Divider style={{ marginTop: '5px', width: '100%' }} />
                        </Grid>
                        <Grid xs={3}></Grid>
                        <Grid xs={1}></Grid>
                        <Grid
                          xs={8}
                          container
                          justifyContent='space-between'
                          alignItems='center'
                          paddingRight={4}
                          height={"60px"}
                        >
                          <Typography fontSize={18} fontWeight='bold' component='h2'>
                            Free Questions
                          </Typography>
                          <Typography fontSize={18} fontWeight={'bold'} color='black' component='h2'>
                            {plan?.freeQuestions}
                          </Typography>
                          <Divider style={{ marginTop: '5px', width: '100%' }} />
                        </Grid>
                        <Grid xs={3}></Grid>
                        <Grid xs={1}></Grid>
                        <Grid
                          xs={8}
                          container
                          justifyContent='space-between'
                          alignItems='center'
                          paddingRight={4}
                          height={"60px"}
                        >
                          <Typography fontSize={18} fontWeight='bold' component='h2'>
                            Free Learning Cards
                          </Typography>
                          <Typography fontSize={18} fontWeight={'bold'} color='black' component='h2'>
                            {plan?.freeKnowledgeCards}
                          </Typography>
                          <Divider style={{ marginTop: '5px', width: '100%' }} />
                        </Grid>
                        <Grid xs={3}></Grid>
                        <Grid xs={1}></Grid>
                        <Grid
                          xs={8}
                          container
                          justifyContent='space-between'
                          alignItems='center'
                          height={"60px"}
                        >
                          <Typography fontSize={18} fontWeight='bold' component='h2'>
                            Support
                          </Typography>
                          <Grid container>
                            {plan?.support && (
                              <Typography
                                variant='h6'
                                color='text.secondary'
                                component='h2'
                                marginRight={1}
                              >
                                {plan?.support}
                              </Typography>
                            )}
                          </Grid>

                          <Divider style={{ marginTop: '5px', width: '100%' }} />
                        </Grid>
                        <Grid xs={3}></Grid>

                      </Grid>
                      <Grid xs={1}></Grid>
                      <Grid xs={1}></Grid>
                      {
                        roleIsValidPlanEdit &&
                        <Grid xs={5} marginTop={-10}>
                          <Grid xs={12} container
                            justifyContent='start '
                            alignItems='center'>
                            <Typography fontSize={18} component='h2'>
                              Current Plan
                            </Typography>
                            <Typography fontSize={18} marginLeft={4} fontWeight='bold' component='h2'>
                              {plan?.name}
                            </Typography>
                          </Grid>

                          <Grid xs={12}
                            justifyContent='start'
                            alignItems='center'
                            marginTop={2}>
                            <TextField
                              select
                              /*  label="Choose your plan" */
                              value={selectedPlan}
                              onChange={handleChangePlan}
                              sx={{ width: "50%" }}
                            >
                              {planOptions.map((option) => (
                                <MenuItem key={option.value} value={option.value}>
                                  {option.label}
                                </MenuItem>
                              ))}
                            </TextField>
                          </Grid>
                        </Grid>
                      }


                      <Grid xs={12} marginTop={1} marginBottom={1}>
                        <Divider variant='middle' sx={{ borderTop: '2px solid' }} />
                      </Grid>
                      <Grid
                        xs={12}
                        style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '20px' }}
                      >
                        {
                          roleIsValidPlanEdit &&
                          <Button variant='contained' disabled={!planChanged} onClick={() => setOpenWarnModalPlanFirst(true)} >Change Plan</Button>
                        }

                        {/*  {roleIsValidPlanEdit && (
                          <ChangePlan
                            buttonClassName='btn btn-primary float-end'
                            buttonLabel='Change Plan'
                            customerId={customerIdGetLink}
                            first={first}
                            setfirst={setfirst}
                          />
                        )} */}
                      </Grid>
                    </Grid>
                  </Box>
                </TabPanel>
                //#endregion
              }
              {
                //#region  Billing
                <TabPanel value={tabValue} index={2}>
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                      {/*    <Grid xs={12}>
                        <Typography variant='h4' fontWeight={'bold'} component='h2'>
                          Billing
                        </Typography>
                        <Divider style={{marginTop: '5px'}} />
                      </Grid> */}
                      <Grid xs={5}>
                        <Typography variant='h5' color='text.secondary' component='h2'>
                          Billing Name
                        </Typography>
                      </Grid>
                      <Grid xs={5}>
                        <Typography variant='h5' fontWeight='bold' component='h2'>
                          {products?.commercialTitle}
                        </Typography>
                      </Grid>
                      <Grid xs={5}>
                        <Typography variant='h5' color='text.secondary' component='h2'>
                          Country
                        </Typography>
                      </Grid>
                      <Grid xs={5}>
                        <Typography variant='h5' fontWeight='bold' component='h2'>
                          {products?.country}
                        </Typography>
                      </Grid>
                      <Grid xs={5}>
                        <Typography variant='h5' color='text.secondary' component='h2'>
                          Province
                        </Typography>
                      </Grid>
                      <Grid xs={5}>
                        <Typography variant='h5' fontWeight='bold' component='h2'>
                          {products?.province}
                        </Typography>
                      </Grid>
                      <Grid xs={5}>
                        <Typography variant='h5' color='text.secondary' component='h2'>
                          City
                        </Typography>
                      </Grid>
                      <Grid xs={5}>
                        <Typography variant='h5' fontWeight='bold' component='h2'>
                          {products?.city}
                        </Typography>
                      </Grid>
                      <Grid xs={5}>
                        <Typography variant='h5' color='text.secondary' component='h2'>
                          Address
                        </Typography>
                      </Grid>
                      <Grid xs={5}>
                        <Typography variant='h5' fontWeight='bold' component='h2'>
                          {products?.address}
                        </Typography>
                      </Grid>
                      <Grid xs={5}>
                        <Typography variant='h5' color='text.secondary' component='h2'>
                          Tax ID
                        </Typography>
                      </Grid>
                      <Grid xs={5}>
                        <Typography variant='h5' fontWeight='bold' component='h2'>
                          {products?.taxId}
                        </Typography>
                      </Grid>
                      <Grid xs={5}>
                        <Typography variant='h5' color='text.secondary' component='h2'>
                          Tax Office
                        </Typography>
                      </Grid>
                      <Grid xs={5}>
                        <Typography variant='h5' fontWeight='bold' component='h2'>
                          {products?.taxOffice}
                        </Typography>
                      </Grid>
                      <Grid xs={5}>
                        <Typography variant='h5' color='text.secondary' component='h2'>
                          Company Phone
                        </Typography>
                      </Grid>
                      <Grid xs={5}>
                        <Typography variant='h5' fontWeight='bold' component='h2'>
                          {products?.companyPhone}
                        </Typography>
                      </Grid>
                      <Grid xs={5}>
                        <Typography variant='h5' color='text.secondary' component='h2'>
                          Web Site
                        </Typography>
                      </Grid>
                      <Grid xs={5}>
                        <Typography variant='h5' fontWeight='bold' component='h2'>
                          {products?.webSite}
                        </Typography>
                      </Grid>
                      <Grid xs={5}>
                        <Typography variant='h5' color='text.secondary' component='h2'>
                          Time Zone
                        </Typography>
                      </Grid>
                      <Grid xs={5}>
                        <Typography variant='h5' fontWeight='bold' component='h2'>
                          {products?.timezone}
                        </Typography>
                      </Grid>
                      <Grid xs={5}>
                        <Typography variant='h5' color='text.secondary' component='h2'>
                          Currency
                        </Typography>
                      </Grid>
                      <Grid xs={5}>
                        <Typography variant='h5' fontWeight='bold' component='h2'>
                          {products?.currency}
                        </Typography>
                      </Grid>
                      <Grid xs={5}>
                        <Typography variant='h5' color='text.secondary' component='h2'>
                          Payment Type
                        </Typography>
                      </Grid>
                      <Grid xs={5}>
                        <Typography variant='h5' fontWeight='bold' component='h2'>
                          {products?.paymentType}
                        </Typography>
                      </Grid>
                      {/* <Grid xs={5}>
                        <Typography variant='h5' color='text.secondary' component='h2'>
                          Payment Frequency
                        </Typography>
                      </Grid>
                      <Grid xs={5}>
                        <Typography variant='h5' fontWeight='bold' component='h2'>
                          {products?.paymentFrequency}
                        </Typography>
                      </Grid> */}
                      <Grid xs={5}>
                        <Typography variant='h5' color='text.secondary' component='h2'>
                          Payment per invoice(inc.tax)
                        </Typography>
                      </Grid>
                      <Grid xs={5}>
                        <Typography variant='h5' fontWeight='bold' component='h2'>
                          {products?.paymentPerInvoice}
                        </Typography>
                      </Grid>
                      <Grid xs={12}>
                        <Divider variant='middle' sx={{ borderTop: '2px solid' }} />
                      </Grid>
                      <Grid
                        xs={12}
                        style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '20px' }}
                      >
                        <EditBillingInfo
                          customer={products}
                          first={first}
                          setfirst={setfirst}
                          customerId={customerIdGetLink}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </TabPanel>
                //#endregion
              }
              {
                //#region  Contacts
                <TabPanel value={tabValue} index={3}>
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2} rowGap={2} justifyContent='space-between'>
                      {/*    <Grid xs={12}>
                      <Typography variant='h4' fontWeight={'bold'} component='h2'>
                        Billing
                      </Typography>
                      <Divider style={{marginTop: '5px'}} />
                    </Grid> */}
                      {products?.contacts?.map((contacts: any, i: number) => {
                        return (
                          <Grid
                            key={i}
                            container
                            xs={5}
                            bgcolor='#e8e8e8'
                            borderRadius={5}
                            marginTop={i !== 0 && i > 1 ? 2 : 0}
                          >
                            <Grid xs={12}>
                              <Typography variant='h5' style={{ fontWeight: 'bold' }} color='#707070'>
                                {contacts?.contactRoleName}
                              </Typography>
                            </Grid>
                            <Grid xs={5}>
                              <Typography variant='h5' color='text.secondary' component='h2'>
                                Name
                              </Typography>
                            </Grid>
                            <Grid xs={5}>
                              <Typography variant='h5' fontWeight='bold' component='h2'>
                                {contacts?.contactName} {contacts?.contactSurname}
                              </Typography>
                            </Grid>
                            <Grid xs={5}>
                              <Typography variant='h5' color='text.secondary' component='h2'>
                                Phone
                              </Typography>
                            </Grid>
                            <Grid xs={5}>
                              <Typography variant='h5' fontWeight='bold' component='h2'>
                                {contacts?.contactPhone}
                              </Typography>
                            </Grid>
                            <Grid xs={5}>
                              <Typography variant='h5' color='text.secondary' component='h2'>
                                Email
                              </Typography>
                            </Grid>
                            <Grid xs={5}>
                              <Typography variant='h5' fontWeight='bold' component='h2'>
                                {contacts?.contactEmail}
                              </Typography>
                            </Grid>
                          </Grid>
                        )
                      })}
                      <Grid xs={12}>
                        <Divider variant='middle' sx={{ borderTop: '2px solid' }} />
                      </Grid>
                      <Grid
                        xs={12}
                        style={{
                          display: 'flex',
                          justifyContent: 'flex-end',
                          paddingRight: '20px',
                          marginTop: '-15px',
                        }}
                      >
                        <MainContacts
                          Contacts={contacts}
                          customerid={/* location?.state?.customerId */ customerIdGetLink}
                          setfirst={setfirst}
                          first={first}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </TabPanel>
                //#endregion
              }
              {
                //#region Notes
                <TabPanel value={tabValue} index={4}>
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                      {/*    <Grid xs={12}>
                    <Typography variant='h4' fontWeight={'bold'} component='h2'>
                      Billing
                    </Typography>
                    <Divider style={{marginTop: '5px'}} />
                  </Grid> */}
                      <Grid xs={12}>
                        <div className='card'>
                          <div className='card-header '>
                            {' '}
                            <span style={{ fontWeight: 'bold', fontSize: '26px' }} className=' my-auto'>
                              Notes
                            </span>{' '}
                          </div>
                          <div className='card-body'>
                            {products?.notes?.map((item: any) => {
                              return (
                                <>
                                  <div className='row'>
                                    <div className='col-11 '>
                                      <span className='fs-2'>
                                        <div dangerouslySetInnerHTML={{ __html: item.note }} />
                                      </span>
                                    </div>
                                    <div className='col-1'>
                                      <button
                                        className='btn'
                                        onClick={() => deleteNote(item?.noteId)}
                                      >
                                        {' '}
                                        <span className=' svg-icon svg-icon-2x'>
                                          <svg
                                            xmlns='http://www.w3.org/2000/svg'
                                            width='25'
                                            height='23.214'
                                            viewBox='0 0 25 23.214'
                                          >
                                            <g id='trash-bin' transform='translate(-32 -48)'>
                                              <rect
                                                id='Rectangle_2203'
                                                data-name='Rectangle 2203'
                                                width='25'
                                                height='4.787'
                                                rx='2.394'
                                                transform='translate(32 48)'
                                                fill='#f1416c'
                                              />
                                              <path
                                                id='Path_2378'
                                                data-name='Path 2378'
                                                d='M66.849,160a.423.423,0,0,0-.328.158.513.513,0,0,0-.112.374l1.447,15.221a.1.1,0,0,0,0,.013,2.739,2.739,0,0,0,2.623,2.555H83.187a2.739,2.739,0,0,0,2.621-2.555v-.013l1.445-15.222a.512.512,0,0,0-.112-.374.424.424,0,0,0-.328-.158Zm13.685,10.89a1.031,1.031,0,0,1,0,1.364.826.826,0,0,1-1.244,0l-2.457-2.693-2.458,2.693a.826.826,0,0,1-1.244,0,1.031,1.031,0,0,1,0-1.364l2.458-2.693L73.131,165.5a1.031,1.031,0,0,1,0-1.364.826.826,0,0,1,1.244,0l2.458,2.693,2.457-2.693a.826.826,0,0,1,1.244,0,1.031,1.031,0,0,1,0,1.364L78.077,168.2Z'
                                                transform='translate(-32.333 -107.107)'
                                                fill='#f1416c'
                                              />
                                            </g>
                                          </svg>
                                        </span>
                                      </button>
                                    </div>
                                  </div>
                                  <br />
                                  <span style={{ fontWeight: 'bold' }} className='fs-2'>
                                    {item.createdName} {item?.createdDate?.slice(0, 10)}{' '}
                                    {item?.createdDate?.slice(11, 16)}
                                  </span>
                                  <br />
                                  <hr className='border border-dashed border-dark' />
                                </>
                              )
                            })}
                          </div>
                        </div>
                      </Grid>
                      <Grid xs={12}>
                        <Divider variant='middle' sx={{ borderTop: '2px solid' }} />
                      </Grid>
                      <Grid
                        xs={12}
                        style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '20px' }}
                      >
                        <EditNotes customerId={customerIdGetLink} first={first} setfirst={setfirst} />
                        {/* <Kvkk /> */}
                      </Grid>
                    </Grid>
                  </Box>
                </TabPanel>
                //#endregion
              }
              {
                //#region Free Mobixa Contents
                <TabPanel value={tabValue} index={5}>
                  <Box sx={{ flexGrow: 1 }}>
                    <Grid container spacing={2}>
                      {/* <Grid xs={6}>
                        <Typography variant='h6' color='text.secondary' component='h2'>
                          Please select the categories of your free questions. <br />
                          Note that you can not change. Edit or redistribute free contents.
                        </Typography>
                        <Divider style={{marginTop: '5px'}} />
                      </Grid> */}
                      <Grid xs={12} display={'flex'} justifyContent={'center'} textAlign={'center'}>
                        <Grid
                          xs={1}
                          display={'flex'}
                          justifyContent='center'
                          flexDirection={'column'}
                          marginRight={5}
                        >
                          <Tooltip
                            arrow
                            title={
                              <Typography fontSize={12} /* style={{background:"9c27b0"}} */>
                                Please select the categories of your free questions. Note that you can
                                not change. Edit or redistribute free contents.
                              </Typography>
                            }
                            placement='left'
                          >
                            <Typography variant='h6' fontWeight={'bold'} component='h2'>
                              Credit
                            </Typography>
                          </Tooltip>
                          <Typography
                            variant='h6'
                            fontWeight={'bold'}
                            bgcolor='#DEE2E6'
                            borderRadius={2}
                            component='h2'
                          >
                            {mobixaContent.credit}
                          </Typography>
                        </Grid>

                        <Grid
                          xs={1}
                          display={'flex'}
                          justifyContent='center'
                          flexDirection={'column'}
                        >
                          <Typography variant='h6' fontWeight={'bold'} component='h2'>
                            Selected
                          </Typography>
                          <Typography
                            variant='h6'
                            fontWeight={'bold'}
                            sx={{
                              bgcolor:
                                mobixaContent.credit === mobixaContent.checkedTotalQuestionCount
                                  ? 'secondary.main'
                                  : '#DEE2E6',
                            }}
                            style={{
                              color:
                                mobixaContent.credit === mobixaContent.checkedTotalQuestionCount
                                  ? 'white'
                                  : '',
                            }}
                            borderRadius={2}
                            component='h2'
                          >
                            {mobixaContent.credit === mobixaContent.checkedTotalQuestionCount
                              ? '✓ ' + mobixaContent.checkedTotalQuestionCount
                              : mobixaContent.checkedTotalQuestionCount}
                          </Typography>
                        </Grid>
                        {/*  <Grid xs={1} textAlign='center'>
                        
                        </Grid> */}
                      </Grid>

                      <Grid xs={4}></Grid>
                      <Grid xs={12} container justifyContent='center' marginTop={2}>
                        <div
                          className=' d-flex card-body pt-0'
                          style={{
                            overflowY: 'scroll',
                            /*  overflowX: 'scroll', */
                            minHeight: '600px',
                            /*  height: '600px', */
                          }}
                        >
                          {categories?.map((data: any) => (
                            <>
                              <div>
                                {
                                  //1.katman
                                }
                                <AccordionMui
                                  style={{
                                    marginRight: 20,
                                    borderRadius: '10px 10px 5px 5px',
                                    border: '1px solid #d5d5d5',
                                    minWidth: '340px',
                                  }}
                                  defaultExpanded={true}
                                >
                                  <AccordionSummary
                                    /* expandIcon={
                                                <ExpandMoreIcon fontSize='large' style={{color: 'black'}} />
                                              } */
                                    style={{
                                      background: '#D5D5D5',
                                      borderRadius: '10px 10px 0px 0px',
                                      padding: '0px 5px 0px 5px',
                                      minHeight: '60px',
                                    }}
                                  >
                                    <div
                                      className='d-flex '
                                      style={{
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        height: 20,
                                        width: '100%',
                                      }}
                                    >
                                      {/*  <div>
                                          <FormControlLabel
                                            control={
                                              <Android12Switch
                                                defaultChecked
                                                color='success'
                                                size='medium'
                                                checked={hasAllChildrenSelected(data.children, selected)}
                                                onClick={(e) => {
                                                  if (!selected?.includes(data.categoryId)) {
                                                    setSelected((current: any) => [...current, data.categoryId])
                                                    data?.children?.map((child: any) => {
                                                      setSelected((current: any) =>
                                                        current.filter((index: any) => index !== child.categoryId)
                                                      )
                                                      if (child.children && child.children.length > 0) {
                                                        child.children.map((child2: any) => {
                                                          setSelected((current: any) =>
                                                            current.filter(
                                                              (index: any) => index !== child2.categoryId
                                                            )
                                                          )
                                                        })
                                                        setSelected((current: any) => [
                                                          ...current,
                                                          ...child.children.map((item: any) => item.categoryId),
                                                        ])
                                                      }
                                                    })
                                                    setSelected((current: any) => [
                                                      ...current,
                                                      ...data?.children.map((item: any) => item.categoryId),
                                                    ])
                                                  } else {
                                                    setSelected((current: any) =>
                                                      current.filter((index: any) => index !== data.categoryId)
                                                    )
                                                    data?.children?.map((child: any) => {
                                                      setSelected((current: any) =>
                                                        current.filter((index: any) => index !== child.categoryId)
                                                      )
                                                      child.children.map((child2: any) => {
                                                        setSelected((current: any) =>
                                                          current.filter(
                                                            (index: any) => index !== child2.categoryId
                                                          )
                                                        )
                                                      })
                                                    })
                                                  }
                                                  e.stopPropagation()
                                                }}
                                                value={data.categoryId}
                                              />
                                            }
                                            label=''
                                          />
                                          
                                        </div> */}
                                      <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <div
                                          style={{
                                            fontWeight: 'bold',
                                            cursor: 'pointer',
                                            fontSize: '12px',
                                            marginLeft: '-4px',
                                          }}
                                        >
                                          {data.name.charAt(0).toUpperCase().replace('🅜', '') +
                                            data.name.slice(1)}
                                        </div>
                                        <span
                                          title='Number of available questions'
                                          className='text-dark  ms-2'
                                          style={{
                                            fontSize: '10px',
                                            color: '#8e8e8e',
                                            fontWeight: 'bold',
                                          }}
                                        >
                                          ({data.questionCount})
                                        </span>
                                      </div>
                                      <div
                                        style={{
                                          display: 'flex',
                                          alignItems: 'end',
                                          justifyContent: 'end',
                                          height: '18px',
                                        }}
                                      >
                                        <TextField
                                          size='small'
                                          value={data.questionCount}
                                          disabled={true}
                                          type='number'
                                          variant='outlined'
                                          style={{
                                            width: '44px',
                                            height: 18,
                                            marginTop: '-4px',
                                            marginRight: '3px',
                                          }}
                                          inputProps={{
                                            min: 1,
                                            max: data.questionCount,
                                            style: {
                                              padding: '0px', // Input padding'ini azaltın
                                            },
                                          }}
                                        />
                                        {
                                          // Question Count open false
                                        }

                                        {
                                          <TextField
                                            size='small'
                                            value={data?.selectedQuestionCount}
                                            disabled={true}
                                            style={{
                                              width: '44px',
                                              height: 18,
                                              marginTop: '-4px',
                                              marginRight: '3px',
                                              marginLeft: 4,
                                            }}
                                            inputProps={{
                                              min: 1,
                                              max: data.selectedQuestionCount,
                                              style: {
                                                padding: '0px', // Input padding'ini azaltın
                                              },
                                            }}
                                          />
                                        }
                                      </div>
                                    </div>
                                  </AccordionSummary>
                                  <AccordionDetails style={{ padding: '3px' }}>
                                    {
                                      //2. katman
                                    }

                                    {data?.children?.map((child: any) =>
                                      child.children.length > 0 ? (
                                        <>
                                          <AccordionMui
                                            style={{
                                              marginTop: '5px',
                                              marginBottom: '5px',
                                              boxShadow: 'none',
                                              borderRadius: '10px 10px 0px 0px',
                                            }}
                                            defaultExpanded={true}
                                          >
                                            <AccordionSummary
                                              sx={{
                                                margin: '0px!important',
                                                '.MuiAccordionSummary-content': {
                                                  // AccordionSummary'nin içeriği için stil
                                                  /* marginLeft: '4px', */
                                                  // Sol marjin değerini 0px olarak ayarlayın
                                                },
                                              }}
                                              style={{
                                                borderRadius: '10px 10px 0px 0px',
                                                padding: '0px 0px 0px 0px',
                                                minHeight: '20px',
                                                marginLeft: '0px!important',
                                                background:
                                                  hover === child.categoryId ? '#e7e7e7' : '',
                                              }}
                                            >
                                              <div
                                                className='d-flex'
                                                style={{
                                                  alignItems: 'center',
                                                  justifyContent: 'space-between',
                                                  width: '100%',
                                                  height: 20,
                                                }}
                                                onMouseEnter={(e: any) => {
                                                  setHover(child.categoryId)
                                                }}
                                                onMouseLeave={(e: any) => {
                                                  setHover('')
                                                }}
                                              >
                                                {/* <div>
                                                    <FormControlLabel
                                                      control={
                                                        <Android12Switch
                                                          defaultChecked
                                                          color='success'
                                                          size='medium'
                                                          value={child.categoryId}
                                                          checked={child.children.every((r: any) =>
                                                            selected?.includes(r.categoryId)
                                                          )}
                                                          onClick={(e: any) => {
                                                            if (!selected?.includes(child.categoryId)) {
                                                              setSelected((current: any) => [
                                                                ...current,
                                                                child.categoryId,
                                                              ])
                                                              child?.children?.map((child: any) => {
                                                                setSelected((current: any) =>
                                                                  current.filter(
                                                                    (index: any) => index !== child.categoryId
                                                                  )
                                                                )
                                                              })
                                                              setSelected((current: any) => [
                                                                ...current,
                                                                ...child?.children.map(
                                                                  (item: any) => item.categoryId
                                                                ),
                                                              ])
                                                            } else {
                                                              setSelected((current: any) =>
                                                                current.filter(
                                                                  (index: any) => index !== child.categoryId
                                                                )
                                                              )
                                                              setSelected((current: any) =>
                                                                current.filter(
                                                                  (index: any) => index !== data.categoryId
                                                                )
                                                              )
                                                              child?.children?.map((child: any) => {
                                                                setSelected((current: any) =>
                                                                  current.filter(
                                                                    (index: any) => index !== child.categoryId
                                                                  )
                                                                )
                                                              })
                                                            }
                                                            e.stopPropagation()
                                                          }}
                                                        />
                                                      }
                                                      label=''
                                                    />
                                                  </div> */}
                                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                                  <span
                                                    className={'text-gray-800 d-flex'}
                                                    style={{
                                                      fontWeight: 'bold',
                                                      cursor: 'pointer',
                                                      fontSize: '12px',
                                                      marginLeft: '-4px',
                                                    }}
                                                  >
                                                    {child.name.charAt(0).toUpperCase() +
                                                      child.name.slice(1)}
                                                  </span>
                                                </div>
                                                {/* <span
                                                    title='Number of available questions'
                                                    className='text-dark text-muted ms-2'
                                                    style={{
                                                      fontSize: '10px',
                                                      color: '#8e8e8e',
                                                      fontWeight: 'normal',
                                                    }}
                                                  >
                                                    ({child.questionCount})
                                                  </span> */}
                                              </div>
                                              <div
                                                style={{
                                                  display: 'flex',
                                                  alignItems: 'end',
                                                  justifyContent: 'end',
                                                  height: '18px',
                                                }}
                                              >
                                                <TextField
                                                  size='small'
                                                  value={child.questionCount}
                                                  disabled={true}
                                                  type='number'
                                                  variant='outlined'
                                                  style={{
                                                    width: '44px',
                                                    height: 18,
                                                    marginTop: '-4px',
                                                    marginRight: '5px',
                                                  }}
                                                  inputProps={{
                                                    min: 1,
                                                    max: child.questionCount,
                                                    style: {
                                                      padding: '0px', // Input padding'ini azaltın
                                                    },
                                                  }}
                                                />
                                                {
                                                  // Question Count open false
                                                }

                                                {
                                                  <TextField
                                                    size='small'
                                                    value={child?.selectedQuestionCount}
                                                    disabled={true}
                                                    style={{
                                                      width: '44px',
                                                      height: 18,
                                                      marginTop: '-4px',
                                                      marginRight: '3px',
                                                      marginLeft: '2px',
                                                    }}
                                                    inputProps={{
                                                      min: 1,
                                                      max: child.selectedQuestionCount,
                                                      style: {
                                                        padding: '0px', // Input padding'ini azaltın
                                                      },
                                                    }}
                                                  />
                                                }
                                              </div>
                                            </AccordionSummary>
                                            <AccordionDetails style={{ padding: '3px' }}>
                                              {child?.children?.map((child: any) => (
                                                <div id={child.categoryId} className='ms-0 mt-3 '>
                                                  <div
                                                    className='d-flex'
                                                    style={{
                                                      alignItems: 'center',
                                                      justifyContent: 'space-between',
                                                      height: 30,
                                                      paddingLeft: 2,
                                                      background:
                                                        hover === child.categoryId ? '#e7e7e7' : '',
                                                    }}
                                                    onMouseEnter={(e: any) => {
                                                      setHover(child.categoryId)
                                                    }}
                                                    onMouseLeave={(e: any) => {
                                                      setHover('')
                                                    }}
                                                  >
                                                    <div
                                                      style={{ display: 'flex', alignItems: 'center' }}
                                                    >
                                                      <div>
                                                        <FormControlLabel
                                                          control={
                                                            <Android12Switch
                                                              defaultChecked
                                                              color='secondary'
                                                              size='medium'
                                                              value={child.categoryId}
                                                              disabled={true}
                                                              checked={
                                                                /* selected?.includes(child.categoryId) */ child?.checked
                                                              }
                                                            />
                                                          }
                                                          label=''
                                                        />
                                                      </div>
                                                      <a
                                                        className={'text-gray-800 d-flex '}
                                                        style={{
                                                          cursor: 'pointer',
                                                          fontSize: '12px',
                                                          alignItems: 'center',
                                                          maxWidth: '210px',
                                                          marginLeft: '-4px',
                                                        }}
                                                      >
                                                        {child.name
                                                          .charAt(0)
                                                          .toUpperCase()
                                                          .replace('🅜', '') + child.name.slice(1)}
                                                      </a>

                                                      {/*  <div
                                                          style={{
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            height: '20px',
                                                          }}
                                                        >
                                                          <span
                                                            title='Number of available questions'
                                                            className='ms-2'
                                                            style={{
                                                              color: '#8e8e8e',
                                                              fontSize: '10px',
                                                            }}
                                                          >
                                                            {`(${child.questionCount})`}
                                                          </span>
                                                        </div> */}
                                                    </div>
                                                    <div
                                                      style={{
                                                        display: 'flex',
                                                        alignItems: 'end',
                                                        justifyContent: 'end',
                                                        height: '18px',
                                                      }}
                                                    >
                                                      <TextField
                                                        size='small'
                                                        value={child?.questionCount}
                                                        disabled={true}
                                                        type='number'
                                                        variant='outlined'
                                                        style={{
                                                          width: '44px',
                                                          height: 18,
                                                          marginTop: '-4px',
                                                          /*  marginRight: '3px', */
                                                        }}
                                                        inputProps={{
                                                          min: 1,
                                                          max: child.questionCount,
                                                          style: {
                                                            padding: '0px', // Input padding'ini azaltın
                                                          },
                                                        }}
                                                      />
                                                      {
                                                        // Question Count open false
                                                      }

                                                      {
                                                        <TextField
                                                          className='ms-2'
                                                          size='small'
                                                          value={child?.selectedQuestionCount}
                                                          disabled={true}
                                                          style={{
                                                            width: '44px',
                                                            height: 18,
                                                            marginTop: '-4px',
                                                            /*  marginRight: '3px', */
                                                          }}
                                                          inputProps={{
                                                            min: 1,
                                                            max: child.selectedQuestionCount,
                                                            style: {
                                                              padding: '0px', // Input padding'ini azaltın
                                                            },
                                                          }}
                                                        />
                                                      }
                                                    </div>
                                                  </div>
                                                </div>
                                              ))}
                                            </AccordionDetails>
                                          </AccordionMui>
                                        </>
                                      ) : null
                                    )}
                                    {
                                      // 3. katman
                                    }

                                    {data?.children?.map((child: any) =>
                                      child.children.length > 0 ? null : (
                                        <div id={child.categoryId} className='ms-0 mt-3 '>
                                          <div
                                            className='d-flex border-1'
                                            style={{
                                              alignItems: 'center',
                                              justifyContent: 'space-between',
                                              height: 30,
                                              paddingLeft: 4,
                                              background: hover === child.categoryId ? '#e7e7e7' : '',
                                            }}
                                            onMouseEnter={(e: any) => {
                                              setHover(child.categoryId)
                                            }}
                                            onMouseLeave={(e: any) => {
                                              setHover('')
                                            }}
                                          >
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                              <div>
                                                <FormControlLabel
                                                  control={
                                                    <Android12Switch
                                                      size='medium'
                                                      color='secondary'
                                                      value={child.categoryId}
                                                      disabled={true}
                                                      checked={
                                                        /* selected?.includes(child.categoryId) */ child?.checked
                                                      }
                                                    />
                                                  }
                                                  label=''
                                                />
                                              </div>
                                              <a
                                                className={'text-gray-800 d-flex'}
                                                style={{
                                                  cursor: 'pointer',
                                                  fontSize: '12px',
                                                  maxWidth: '210px',
                                                  marginLeft: '-4px',
                                                }}
                                              >
                                                {child.name.charAt(0).toUpperCase() +
                                                  child.name.slice(1)}
                                              </a>

                                              {/*  <div
                                                  style={{
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                    height: '20px',
                                                  }}
                                                >
                                                  <span
                                                    title='Number of available questions'
                                                    className='ms-2'
                                                    style={{color: '#8e8e8e', fontSize: '10px'}}
                                                  >
                                                    {`(${child.questionCount})`}
                                                  </span>
                                                </div> */}
                                            </div>
                                            <div
                                              style={{
                                                display: 'flex',
                                                alignItems: 'end',
                                                justifyContent: 'end',
                                                height: '18px',
                                              }}
                                            >
                                              <TextField
                                                size='small'
                                                value={child.questionCount || 0}
                                                disabled={true}
                                                type='number'
                                                variant='outlined'
                                                style={{
                                                  width: '44px',
                                                  height: 18,
                                                  marginTop: '-4px',
                                                  marginRight: '3px',
                                                }}
                                                inputProps={{
                                                  min: 1,
                                                  max: child.questionCount,
                                                  style: {
                                                    padding: '0px', // Input padding'ini azaltın
                                                  },
                                                }}
                                              />
                                              {
                                                // Question Count open false
                                              }

                                              {
                                                <TextField
                                                  size='small'
                                                  value={child?.selectedQuestionCount}
                                                  disabled={
                                                    /* !selected?.includes(child.categoryId) */ true
                                                  }
                                                  type='text'
                                                  variant='outlined'
                                                  style={{
                                                    width: '44px',
                                                    height: 18,
                                                    marginTop: '-4px',
                                                    marginLeft: '5px',
                                                    marginRight: 3,
                                                  }}
                                                  inputProps={{
                                                    min: 1,
                                                    max: child.selectedQuestionCount,
                                                    style: {
                                                      padding: '0px', // Input padding'ini azaltın
                                                    },
                                                    pattern: '\\d*',
                                                  }}
                                                />
                                              }
                                            </div>
                                          </div>
                                        </div>
                                      )
                                    )}
                                  </AccordionDetails>
                                </AccordionMui>
                              </div>
                            </>
                          ))}
                        </div>
                      </Grid>
                      <Grid xs={12}>
                        <Divider variant='middle' sx={{ borderTop: '2px solid' }} />
                      </Grid>
                      <Grid
                        xs={12}
                        style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '20px' }}
                      >
                        {mobixaContent.editCategoryButton === true && Number(customerIdGetLink) !== 0 && (
                          <MobixaCategory
                            customerId={customerIdGetLink}
                            first={first}
                            setFirst={setfirst}
                          />
                        )}

                        {/* <Kvkk /> */}
                      </Grid>
                    </Grid>
                  </Box>
                </TabPanel>
                //#endregion
              }
              {
                //#region ServiceApı
              <TabPanel value={tabValue} index={6}>
                {
                  serviceLoading ?
                    <Box width={"100%"} height={"100%"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                      <CircularProgress size={26} color='primary' />
                    </Box>
                    :
                    <Grid container>
                      <Grid xs={12} >
                        <Box >
                          <Typography fontSize={14} >Our API helps you to transfer data for full-automatic user creation in Mobixa Database Your secret API keys are listed below. Please note that will NOT display your secret API keys again. You can always produce a new one if you want. Do not share your API key with others, or expose it in the browser or other client-side code. In order to protect the security of your account, MobixaPlus may also disable any API key that we’ve found has leaked publicly. Please save this secret key somewhere safe and accessible. For security reasons, you won’t be able to view it again through your MobixaPlus account. If you lose this secret key, you’ll need to generate a new one. You can find the API documentation
                            <Link href={`${process.env.REACT_APP_API_URL === "https://plusapitest.mobixa.net" ? (process.env.REACT_APP_API_URL + "/mobixaDocs") : (process.env.REACT_APP_API_URL + "/docs")}`} sx={{ cursor: "pointer" }}>here</Link> </Typography>
                        </Box>
                      </Grid>
                      <Grid xs={3} mt={2}>
                        <Box>
                          <Button variant='contained' size='medium' disabled={serviceCreateLoading} onClick={() => createServiceApi()} >
                            CREATE API KEY
                          </Button>
                        </Box>
                      </Grid>
                      {
                        accessKey &&
                        <Grid xs={9} mt={2}>
                          <Box display={"flex"} alignItems={"center"}>
                            <Typography fontSize={12} fontWeight={"bold"}>Access Key</Typography>
                            <Typography fontSize={12} marginLeft={2}>{accessKey}</Typography>
                          </Box>
                          <Box display={"flex"} alignItems={"center"} marginTop={3}>
                            <Typography fontSize={12} fontWeight={"bold"} marginRight={2}>Secret Key</Typography>
                            <Typography fontSize={12} >****************************</Typography>
                          </Box>
                        </Grid>
                      }
                    </Grid>
                }


              </TabPanel>
              //#endregion
              }
            </>
            )}
          </Box>
          <CustomerAddModal1
            openEditCustomerModal={openEditCustomerModal}
            setOpenEditCustomerModal={setOpenEditCustomerModal}
            /*  plans={plans} */
            products={products}
            first={first}
            setFirst={setfirst}
            roleIsValidBasicInfoEdit={roleIsValidBasicInfoEdit}
          />
        </>

      )}
      {
        //warnmodal change plan
      }

      <Dialog
        open={openWarnModalPlan}
        TransitionComponent={Transition}
        keepMounted
        fullWidth
        maxWidth={"xs"}
        PaperProps={{
          style: {
            backgroundColor: '#ED6C02',
          },
        }}
        /*     onClose={handleClickClose} */
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle>
          <Typography color='white' variant='h5'>
            <WarningAmberIcon fontSize='large' /> Warning
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-slide-description' color={'white'}>
            The limits on the customer's current plan are higher than the plan you want to switch to. Please decrease the number of users to be able to select this plan.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant='text'
            onClick={() => setOpenWarnModalPlan(false)}
            style={{ color: 'white' }}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={openWarnModalPlanFirst}
        TransitionComponent={Transition}
        keepMounted
        fullWidth
        maxWidth={"xs"}
        PaperProps={{
          style: {
            backgroundColor: '#ED6C02',
          },
        }}
        /*     onClose={handleClickClose} */
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogTitle>
          <Typography color='white' variant='h5'>
            <WarningAmberIcon fontSize='large' /> Warning
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-slide-description' color={'white'}>
            TYou’re changing the customer’s plan.<br /> Proceed?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant='text'
            onClick={() => handleSave()}
            style={{ color: 'white' }}
          >
            YES
          </Button>
          <IconButton onClick={() => setOpenWarnModalPlanFirst(false)}>
            <CloseIcon sx={{ color: "white" }} />
          </IconButton>
        </DialogActions>
      </Dialog>

      {
        //service modal
      }
      <Dialog
        open={serviceModalOpen}
        TransitionComponent={Transition}
        keepMounted
        fullWidth
        maxWidth={"xs"}
        PaperProps={{
          style: {
            /*   backgroundColor: '#ED6C02', */
            borderRadius: "10px"
          },
        }}
        /*     onClose={handleClickClose} */
        aria-describedby='alert-dialog-slide-description'
      >
        <DialogContent >
          <Grid container>
            {
              serviceCreateLoading ?
                <Grid xs={12} display={"flex"} flexDirection={"column"} alignItems={"center"} justifyContent={"center"}>
                  <Typography fontSize={18} color={"#6C757D"}>Processing...</Typography>
                  <Box display={"flex"} width={"100%"} justifyContent={"center"}>
                    <CircularProgress size={26} sx={{ color: "#000000" }} />
                  </Box>
                </Grid> :
                <Grid xs={12}  >
                  <Box display={"flex"} alignItems={"center"} >
                    <Typography fontSize={12} fontWeight={"bold"}>Access Key</Typography>
                    <Box height={33} width={250} marginLeft={2} sx={{ overflowY: "auto" }} border={"1px solid #707070"} display={"flex"} alignItems={"center"} borderRadius={"5px"} paddingLeft={1} paddingRight={1}> <Typography fontSize={12} fontWeight={"bold"} color={"#6C757D"}>{serviceCreateData?.apiAccessKey}</Typography></Box>
                    <Box marginLeft={1} display={"flex"} flexDirection={"column"} justifyContent={"end"} alignItems={"start"} height={49}>
                      {
                        copyIsSuccess.accessKey &&
                        <Typography fontSize={14} sx={{ color: "#2E7D32" }}>Copied!</Typography>
                      }

                      <Tooltip arrow title="Kopyala">
                        <IconButton size="small" onClick={() => copyToClipboard(serviceCreateData?.apiAccessKey)}>
                          <ContentCopyIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>
                  <Box display={"flex"} alignItems={"center"} marginTop={2}>
                    <Typography fontSize={12} fontWeight={"bold"}>Secret Key</Typography>
                    <Box height={33} width={250} marginLeft={2.7} sx={{ overflowY: "auto" }} border={"1px solid #707070"} display={"flex"} alignItems={"center"} borderRadius={"5px"} paddingLeft={1} paddingRight={1}> <Typography fontSize={12} fontWeight={"bold"} color={"#6C757D"}>{serviceCreateData?.apiSecretKey}</Typography></Box>
                    <Box marginLeft={1} display={"flex"} flexDirection={"column"} justifyContent={"end"} alignItems={"start"} height={49}>
                      {
                        copyIsSuccess.secretKey &&
                        <Typography fontSize={14} sx={{ color: "#2E7D32" }}>Copied!</Typography>
                      }
                      <Tooltip arrow title="Kopyala">
                        <IconButton size="small" onClick={() => copyToClipboard1(serviceCreateData?.apiSecretKey)}>
                          <ContentCopyIcon fontSize="small" />
                        </IconButton>
                      </Tooltip>
                    </Box>
                  </Box>

                </Grid>
            }

          </Grid>

        </DialogContent>
        <DialogActions sx={{ display: "flex", justifyContent: "center" }}>
          <Button
            variant='contained'
            onClick={() => setServiceModalOpen(false)}
            color='inherit'
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default CustomerProfile
